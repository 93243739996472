import audioSrc0 from '../../static/Audio/CONVERSATION_1_SAM_HARRIS.mp3'
import audioSrc1 from '../../static/Audio/CONVERSATION_2_KARA_SWISHER.mp3'
import audioSrc2 from '../../static/Audio/CONVERSATION_3_CHRISTOPHER_HITCHINS.mp3'
import audioSrc3 from '../../static/Audio/CONVERSATION_4_GLADWELL.mp3'
import audioSrc4 from '../../static/Audio/CONVERSATION_5_BUCKLY.mp3'
import audioSrc5 from '../../static/Audio/CONVERSATION_6_CARROLL.mp3'
import audioSrc6 from '../../static/Audio/CONVERSATION_7_DAN_HARMON.mp3'

// Convo0
const audio0 = new Audio(audioSrc0)

// Convo1
const audio1 = new Audio(audioSrc1)

// Convo2
const audio2 = new Audio(audioSrc2)

// Convo3
const audio3 = new Audio(audioSrc3)

// Convo4
const audio4 = new Audio(audioSrc4)

// Convo5
const audio5 = new Audio(audioSrc5)

// Convo6
const audio6 = new Audio(audioSrc6)

const audioArray = [
    audio0,
    audio1,
    audio2,
    audio3,
    audio4,
    audio5,
    audio6,
      
]

export {
    audioArray
}