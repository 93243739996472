//0

import img1_0 from '../../static/IMG/0/0.jpeg' // murray
import img2_0 from '../../static/IMG/0/1.png' // muddlebury
import img3_0 from '../../static/IMG/0/2.png' // VOX
import img4_0 from '../../static/IMG/0/3.jpeg' // turkhimer
import img5_0 from '../../static/IMG/0/4.jpeg' // harden
import img6_0 from '../../static/IMG/0/5.jpeg' // nisbit

//1

//2

//3

//4

//5

//6

import img0_6 from '../../static/IMG/6/0.png'

const imgArray = [
    //0
    [
        {
            img: img1_0,
            source: 'https://static01.nyt.com/images/2020/02/13/books/12bookmurray2-sub/12bookmurray2-sub-mobileMasterAt3x-v3.jpg',
        },

        {
            img: img2_0,
            source: 'https://vtdigger.org/wp-content/uploads/2020/03/Screen-Shot-2020-03-10-at-3.03.58-PM.png',
            },

        {
            img: img3_0,
            source: 'https://download.logo.wine/logo/Vox_(website)/Vox_(website)-Logo.wine.png',
            },

        {
            img: img4_0,
            source: 'https://upload.wikimedia.org/wikipedia/commons/7/76/Eric_Turkheimer_2017.jpg',
        },

        {
            img: img5_0,
            source: 'https://labs.la.utexas.edu/harden/files/2013/05/PaigeFamily-029-copy-1024x1022.jpg',
        },

        {
            img: img6_0,
            source: 'https://upload.wikimedia.org/wikipedia/commons/3/3c/Richard_Nisbett%2C_2014-1.jpg',
        }

    ],

    //1
    [
        {
            img: img1_0,
            source: 'https://static01.nyt.com/images/2020/02/13/books/12bookmurray2-sub/12bookmurray2-sub-mobileMasterAt3x-v3.jpg',
        },
    ],

    //2
    [
        {
            img: img6_0,
            source: 'https://upload.wikimedia.org/wikipedia/commons/3/3c/Richard_Nisbett%2C_2014-1.jpg',
        }
    ],

    //3
    [

        {
            img: img6_0,
            source: 'https://upload.wikimedia.org/wikipedia/commons/3/3c/Richard_Nisbett%2C_2014-1.jpg',
        }

    ],

    //4
    [

    {
        img: img6_0,
        source: 'https://upload.wikimedia.org/wikipedia/commons/3/3c/Richard_Nisbett%2C_2014-1.jpg',
    }

    ],

    //5
    [

    {
        img: img6_0,
        source: 'https://upload.wikimedia.org/wikipedia/commons/3/3c/Richard_Nisbett%2C_2014-1.jpg',
    }

    ],

    //6
    [

    {
        img: img0_6,
        source: 'https://upload.wikimedia.org/wikipedia/commons/3/3c/Richard_Nisbett%2C_2014-1.jpg',
    }

    ],

]

export {
    imgArray
}