import data0 from '../../static/DATA/data.json' // sam harris
import data1 from '../../static/DATA/DATA_2.json' // kara
import data2 from '../../static/DATA/DATA_3.json' // hitchines
import data3 from '../../static/DATA/DATA_4.json'
import data4 from '../../static/DATA/DATA_5.json'
import data5 from '../../static/DATA/DATA_6.json'
import data6 from '../../static/DATA/DATA_7.json'

const dataArray = [
    data0,
    data1,
    data2,
    data3,
    data4,
    data5,
    data6,
]

export {
    dataArray
}