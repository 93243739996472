import img0_src from  '../../static/ConvoIMG/0.png'
const img0 = new Audio(img0_src)

import img1_src from  '../../static/ConvoIMG/1.jpg'
const img1 = new Audio(img1_src)

import img2_src from  '../../static/ConvoIMG/2.png'
const img2 = new Audio(img2_src)

import img3_src from  '../../static/ConvoIMG/3.jpg'
const img3 = new Audio(img3_src)

import img4_src from  '../../static/ConvoIMG/4.jpg'
const img4 = new Audio(img4_src)

import img5_src from  '../../static/ConvoIMG/5.jpg'
const img5 = new Audio(img5_src)

import img6_src from  '../../static/ConvoIMG/6.jpeg'
const img6 = new Audio(img6_src)


const ConvoIMGArray = [
    img0_src,
    img1_src,
    img2_src,
    img3_src,
    img4_src,
    img5_src,
    img6_src,
]

export {
    ConvoIMGArray
}