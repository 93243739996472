import gsap from "gsap";
import { axisViewItems, keepTitle } from "./AxisViewItems";

let toggleViewState = false

function toggleViewMode() {

    axisViewItems()

    

    let toggleViewModeAnimation_IntoScrenn = gsap.timeline({paused: true})
    let toggleViewModeAnimation_AxisScreen = gsap.timeline({paused: true, delay: 0.3})
    let toggleViewIconAnimation = gsap.timeline({paused: true})

    let toggleViewMaster = gsap.timeline({paused: true})

    toggleViewIconAnimation
    .to('.toggle-view-button-line:nth-child(1)', {
       rotate: '90deg',
       y: '-20px'
    })
    .to('.toggle-view-button-line:nth-child(2)', {
        rotate: '-90deg',
        y: '20px',
        height: '100%'
     }, "<")

    toggleViewIconAnimation.play()


    toggleViewModeAnimation_IntoScrenn
    .to('.conversation-item', {
        opacity: 0,
        duration: 0.4
    })
    .to('.conversation-item-title', {
        opacity: 1,
        top: '10vh',
        left: '10vw',
        duration: 0.4
    }, "<")
    .to('.divider', {
        opacity: 0,
        duration: 0.4
    }, "<")
    .to('.intro-main-header', {
        opacity: 0,
        duration: 0.4
    }, "<")
    .to('.logo', {
        x: '10vw',
        y: '-2vh',
        scale: '0.7, 0.7',
        duration: 0.7,
        delay: 0.3
    }, "<")
    
    toggleViewModeAnimation_AxisScreen
    .to('.x-axis-line', {
        opacity: 1,
        duration: 0
    })
    .to('.y-axis-line', {
        height: '80vh',
        duration: 0.5,
        delay: 0.1
    })
    .to('#tiles', {
        opacity: 1,
        filter: 'blur(0px)',
        duration: 0.7
    }, "<")
    .to('.item', {
        opacity: 1,
        filter: 'blur(0px)',
        duration: 0.7
    }, "<")
    .to('.axis-name', {
        opacity: 1,
        duration: 0.7
    }, "<")
    .to('.axisy-name', {
        opacity: 1,
        duration: 0.7
    }, "<")
    .to('.menu', {
        opacity: 0,
        pointerEvents: 'none',
        duration: 0.2,
    }, "<")
    
    if (!toggleViewState) {

        $(".axis-container").css({
            display: 'block',
            opacity: 1
        })

        $("#con-info").css({
            opacity: 0
        })

        $('.x-axis-line').css({
            opacity: 1
        })

        $("body").css({
            overflow: 'hidden'
         })

         $(".axis-container").css({
            opacity: 1,
            zIndex: 10
        })

        console.log('switch view')

        // CLEAN INTRO SCREEN TIMELINE

        toggleViewMaster.add(toggleViewModeAnimation_IntoScrenn.play())
                        .add(toggleViewModeAnimation_AxisScreen.play())
                        .add(showAxisViewItems(), '+=1')

        toggleViewMaster.play()

        return toggleViewState = true
    }
    else {
        console.log('switch back')

        let toggleViewModeAnimation_IntoScrenn_REVERSE = gsap.timeline({paused: true, delay: 1})
        toggleViewModeAnimation_IntoScrenn_REVERSE
        .to('.conversation-item', {
            opacity: 1,
            duration: 0.4
        })
        .to('.conversation-item-title', {
            opacity: 1,
            top: '10vh',
            left: '10vw',
            duration: 0.4
        }, "<")
        .to('.divider', {
            opacity: 1,
            duration: 0.4
        }, "<")
        .to('.intro-main-header', {
            opacity: 1,
            duration: 0.4
        }, "<")
        .to('.logo', {
            x: '0vw',
            y: '0vh',
            scale: '1, 1',
            duration: 0.7,
            delay: 0.3
        }, "<")

        let toggleViewModeAnimation_AxisScreen_REVERSE = gsap.timeline({paused: true})
        toggleViewModeAnimation_AxisScreen_REVERSE
        .to('.x-axis-line', {
            opacity: 0,
            duration: 0
        })
        .to('.y-axis-line', {
            height: '0vh',
            duration: 0.5,
            delay: 0.1
        })
        .to('#tiles', {
            opacity: 1,
            filter: 'blur(10px)',
            duration: 0.7
        }, "<")
        .to('.axis-name', {
            opacity: 0,
            duration: 0.7
        }, "<")
        .to('.axisy-name', {
            opacity: 0,
            duration: 0.7
        }, "<")
        .to('.axis-container', {
            display: 'none',
            duration: 0.7
        }, "<")
        .to('.menu', {
            opacity: 1,
            pointerEvents: 'auto',
            duration: 0.2,
        }, "<")

        toggleViewModeAnimation_AxisScreen_REVERSE.play().then(toggleViewModeAnimation_IntoScrenn_REVERSE.play().then(() => {


            let toggleViewIconAnimation = gsap.timeline({paused: true})
            toggleViewIconAnimation
            .to('.toggle-view-button-line:nth-child(1)', {
            rotate: '0deg',
            y: '0px'
            })
            .to('.toggle-view-button-line:nth-child(2)', {
                rotate: '90deg',
                y: '0px',
                height: '130%'
            }, "<")

            toggleViewIconAnimation.play()
        

            $("body").css({
                overflow: '',
                opacity: 1
            })
    
        }))
        




        
        return toggleViewState = false
    }
    
    
    
   
}

const items = document.querySelectorAll('.item')

function showAxisViewItems() {
    for (let i = 0; i < items.length; i++) {
       
        gsap.to(items[i], {
            opacity: 1,
            delay: 0 + '.' + i
        })
        
    }
}

export {
    toggleViewMode
}