import gsap, { Sine } from "gsap"
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollSmoother } from 'gsap/ScrollSmoother'
import { initIndex, initIndexTimeline } from "./initIndex"

var primaryColor = 'rgb(35, 60, 246)'

function Intro() {
    
    const container = document.querySelector('.intro-main')

    const line = document.createElement('div')
    line.classList = "line"

    document.body.append(
        line,
    )

    // Animations

    let lineEnterAnimation = gsap.timeline({delay:1, paused: true,})
    lineEnterAnimation
    .to(line, {
        width: '85vw',
        duration: 0.8,
        ease: Sine.easeOut
    })
    /*
    .to(line, {
        height: '70vh',
        duration: 0.5,
        ease: Sine
    })
    */
    .then(() => {
            initIndex()
            setTimeout(() => {

                let appendIntroTimeline = gsap.timeline()
                appendIntroTimeline
                .to(".intro-container", {
                    opacity: 1,
                    duration: 0
                })
                .to(".intro-container", {
                    opacity: 1,
                    duration: 0
                })
                .to('.divider-line', {
                    opacity: 1,
                    duration: 0
                })
                .to('.divider-num', {
                    opacity: 1,
                    duration: 0.3
                })
                .to('.welcome>p:nth-child(1)', {
                    opacity: 1,
                    duration: 0.3
                }, "<")
                .to('.welcome>p:nth-child(2)', {
                    opacity: 1,
                    duration: 0.3
                }, "<+=0.1")
                .to('.welcome>p:nth-child(3)', {
                    opacity: 1,
                    duration: 0.3
                }, "<+=0.15")
                .to('.welcome>p:nth-child(4)', {
                    opacity: 1,
                    duration: 0.3
                }, "<+=0.2")
                .to('.welcome>p:nth-child(5)', {
                    opacity: 1,
                    duration: 0.3
                }, "<+=0.25")
                .to('.welcome>p:nth-child(6)', {
                    opacity: 1,
                    duration: 0.3
                }, "<+=0.3")
                .to('.conversation-item', {
                    opacity: 1,
                    duration: 0.3
                }, "<-=0.3")
                
                
                /*
                $(".intro-container").css({
                    opacity: 1
                })
                $('.divider-num').css({
                    opacity: 1
                })
                $('.divider-line').css({
                    opacity: 1
                })
                $('.welcome>p:nth-child(1)').css({
                    opacity: 1
                })
                $('.welcome>p:nth-child(2)').css({
                    opacity: 1
                })
                $('.welcome>p:nth-child(3)').css({
                    opacity: 1
                })
                $('.welcome>p:nth-child(4)').css({
                    opacity: 1
                })
                $('.conversation-item').css({
                    opacity: 1
                })
                */
            }, 0);
            
    })


    lineEnterAnimation.play()


    

}

gsap.registerPlugin(ScrollSmoother, ScrollTrigger)

let smoother = ScrollSmoother.create({
    content: '.intro-main',
    wrapper: '.intro-container',
    smooth: 0,
})




export {
    Intro,
    //smoother
}