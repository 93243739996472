import gsap from "gsap"
import { amount, audio, seekSlider, textContainer } from "./InitPlayer"
import { imgArray } from "./IMGArray"
import { Conversations } from "./Info"
import { data } from "./initCon"
import { linkArray } from "./LinkArray"
import { exToPlus, plusToEx, plusToRead, readToPlus } from "./Cursor";

const links = document.getElementsByClassName('link')
const placeholder = document.createElement('span')
let linkTop 
let linkLeft
let linkHeight
let linkWidth

let active = false



function linkClick(index) {
    
    for (let i = 0; i < links.length; i++) {

        links[i].addEventListener('click', () => {
            if (!active) {
                activateLink(i)
                initLinkInfo(i, index)
            }
            
            else {
                deactivateLink(i)
                removeLinkInfo()
                
            }      
        })
    }
}


// Timelines

let imgTimeline = gsap.timeline({paused:true, delay: 0.5})
let imgInfoTimeline = gsap.timeline({paused:true, delay: 1})
let linkMainTimeline = gsap.timeline({paused:true, delay: 1.5})
let cleanScreen = gsap.timeline({paused: true})
let linkItemTimeline = gsap.timeline({paused: true})
let linkItemBackToPlace = gsap.timeline({paused: true})


const imgWrapper = document.createElement('div')
const imgContainer = document.createElement('div')
const img = document.createElement('img')

const imgInfo = document.createElement('div')
const imgInfoItem1 = document.createElement('div')
const imgInfoItem2 = document.createElement('div')
const imgInfoItem3 = document.createElement('div')

const linkMain = document.createElement('div')
const linkMainClose = document.createElement('div')

linkMain.classList = 'link-main'
imgWrapper.append(imgContainer)
imgWrapper.append(imgInfo)
imgContainer.append(img)
imgInfo.append(imgInfoItem1, imgInfoItem2, imgInfoItem3)

function initLinkInfo(index, parentIndex) {
    
    imgWrapper.classList = 'img-wrapper'

    imgContainer.classList = 'img-container'
    img.src = imgArray[parentIndex][index].img

    imgInfo.classList = 'img-info'

    imgInfoItem1.classList = 'img-info-item img-info-item1'
    imgInfoItem1.textContent = imgArray[parentIndex][index].source

    imgInfoItem2.classList = 'img-info-item img-info-item2'
    imgInfoItem2.textContent = data.entities[index].entity_type

    imgInfoItem3.classList = 'img-info-item img-info-item3'
    imgInfoItem3.textContent = index + ' / ' + data.entities.length

    for (let i = 0; i < linkArray[parentIndex][index].length; i++) {
   
        const linkMainItem = document.createElement('div')
        linkMainItem.classList = 'link-main-item'

        linkMainItem.addEventListener('click', () => {
            window.open(linkArray[parentIndex][index][i].link , '_blank').focus();
        })

        const para1 = document.createElement('p')
        para1.classList = 'link-main-title'
        para1.textContent = linkArray[parentIndex][index][i].title
        linkMainItem.append(para1)

        const para2 = document.createElement('p')
        para2.classList = 'link-main-link'
        para2.textContent = linkArray[parentIndex][index][i].link
        linkMainItem.append(para2)

        const para3 = document.createElement('p')
        para3.classList = 'link-main-source'
        para3.textContent = 'source: ' + linkArray[parentIndex][index][i].source
        linkMainItem.append(para3)

        linkMain.append(linkMainItem)
    
    }

    textContainer.append(imgWrapper, linkMain)

    imgTimeline.to(imgContainer, {
        clipPath: 'polygon(0 0%, 100% 0%, 100% 100%, 0% 100%)',
        duration: 0.8,
    })

    imgInfoTimeline
    .to(imgInfoItem1, {
        opacity: 1,
        duration: 0.3
    })
    .to(imgInfoItem2, {
        opacity: 1,
        duration: 0.3
    })
    .to(imgInfoItem3, {
        opacity: 1,
        duration: 0.3
    })


    linkMainTimeline
    .to('.link-main', {
        opacity: 1,
        duration: 0.3
    })

    

    imgTimeline.play().then(
        imgInfoTimeline.play().then(
            linkMainTimeline.play()
        )
    )
    
   

   
}

function removeLinkInfo() {

    imgTimeline.reverse()

    imgInfoTimeline
    .to(imgInfoItem1, {
        opacity: 0,
        duration: 0.3
    })
    .to(imgInfoItem2, {
        opacity: 0,
        duration: 0.3
    })
    .to(imgInfoItem3, {
        opacity: 0,
        duration: 0.3
    })

    linkMainTimeline.reverse()

    setTimeout(() => {
        linkMain.innerHTML = ''
        textContainer.removeChild(imgWrapper)
        textContainer.removeChild(linkMain)
    }, 1000); 
}

function placePlaceHolder(elem) {
    placeholder.classList = "placeholder"
    placeholder.textContent =  '{' + elem.textContent + '}'
    $(placeholder).css({
        position: 'relative',
        //top: linkTop,
        //left: linkLeft,
        height: linkHeight,
        width: linkWidth,
        background: 'red',
        marginRight: '0.3vw',
        color: '#fff',
        opacity: 0
    })
    elem.parentElement.insertBefore(placeholder, elem)
    elem.classList = 'word link linkActive'
}

function activateLink(i) {
    console.log('link' + i + ' click')

    // Get Link Attrs

    linkTop =  links[i].getBoundingClientRect().top
    linkLeft =  links[i].getBoundingClientRect().left
    linkHeight =  links[i].getBoundingClientRect().height
    linkWidth =  links[i].getBoundingClientRect().width

    // Placeholder

    placePlaceHolder(links[i])

    // LinkActive Hover

    $('.linkActive').mouseover(() => {
        readToPlus()
        plusToEx()
    })

    $('.linkActive').mouseout(() => {
       exToPlus()
    })

    // Replace Link

    $(links[i]).css({
        position: 'absolute',
        top: linkTop,
        left: linkLeft,
        height: linkHeight,
        width: linkWidth,
        fontSize: '1.5vw'
    })
    document.body.append(links[i])

    // Link To Position Animation

    linkItemTimeline.to(".linkActive", {
        rotateX: 0,
        rotateY: 0,
        textDecoration: 'none',
        fontWeight: '300',
        bottom:  (window.innerHeight/2)+100,
        top: 'auto',
        left: 'auto',
        right: '18vw',
        scale: '4, 4',
        duration: 0.8,
    })

    linkItemTimeline.play()

    // Move Transcript
    
    /*
    $("#transcript-container").css({
        transform: 'rotateY(0deg) rotateX(0deg) translate(0vh, 0vw) scale(0, 0)',
        //transformOrigin: links[i].getBoundingClientRect().top + 'px 0px'
    })
    */
    

    // Init Info Scene 

    linkInfo(i)

    // Return Active Value

    return active = true
}

// Reset Function

function resetConvo() {

    $(".word").removeClass('sub') // reset subs
    amount = 0 // reset scrollAmount
    audio.currentTime = 0 // reset Audio time
    seekSlider.value = 0

}

// Reset 

if (textContainer.scrollTop <= 10) {
    resetConvo
}

function linkInfo(index) {

    // Close

    linkMainClose.classList = 'info-main-close'
    textContainer.append(linkMainClose)

    gsap.to(linkMainClose, {
        opacity: 1,
        duration: 0.3,
        delay: 0.7
    })

    cleanScreen
    .to('#chapters', {
        display: 'none',
        duration: 0,
    })
    .to('#player', {
        pointerEvents: 'none',
        duration: 0,
    })
    .to('#transcript-container',{
        rotateX: 15,
        rotateY: 15,
        translateX: '-65vh',
        scale: '0.9 0.9',
        //transform: 'rotateY(15deg) rotateX(15deg) translate(-65vh, 0vw) scale(0.8, 0.8)',
        filter: 'blur(10px)',
        duration: 0.5
    })
    .to(('.speakers'), {
        pointerEvents: 'none',
        filter: 'blur(12px)',
        opacity: 0.5,
        duration: 0.5,
    }, "<")
    .to('.time', {
        opacity: 0,
        duration: 0.3,
    }, "<0.3")
    .to('.link', {
        color: '#000',
        duration: 0.4,
        delay: 0.3
    }, "<0.3")
    .to('.play', {
        opacity: 0,
        duration: 0.3
    },"<0.4")
    .to('.pause', {
        opacity: 0,
        duration: 0.3
    },"<0.4")
    .to('.speakers-tiny', {
        opacity: 0,
        duration: 0.3
    },"<0.4")
    .to('.go-back-up', {
        opacity: 0,
        duration: 0,
    }, "<0.5")
    .to('.helper', {
        opacity: 0,
        duration: 0.3,
    }, "<0.5") 

    cleanScreen.play()
}

// Main Animation

    

var initLinkClickAnimation = gsap.timeline()
initLinkClickAnimation

function deactivateLink(i) {
    /*
    $("#transcript-container").css({
        transform: 'rotateY(0deg) rotateX(0deg) translate(0vh, 0vw) scale(1, 1)',
        transformOrigin: placeholder.getBoundingClientRect().top + 'px 0px'
    })
    */
    console.log('click close')
    
    linkItemBackToPlace.to(".linkActive", {
        top: linkTop,
        left: linkLeft,
        right: 0,
        bottom: 0,
        x: 0,
        y: 0,
        textDecoration: 'underline',
        fontWeight: '700',
        scale: '1, 1',
        duration: 1
    })


    function exitLinkAnimation() {
        let exitLinkTimeline = gsap.timeline()
        exitLinkTimeline
        .to('#chapters', {
            display: 'block',
            duration: 0,
        })
        .to('#player', {
            pointerEvents: 'auto',
            duration: 0,
        })
        .to('#transcript-container',{
            rotateX: 0,
            rotateY: 0,
            translateX: '0vh',
            scale: '1 1',
            //transform: 'rotateY(15deg) rotateX(15deg) translate(-65vh, 0vw) scale(0.8, 0.8)',
            filter: 'blur(0px)',
            duration: 0.5
        })
        .to(('.speakers'), {
            pointerEvents: 'auto',
            filter: 'blur(0px)',
            opacity: 1,
            //transform: 'rotateY(35deg) rotateX(10deg)',
            duration: 0.5,
        }, "<")
        .to('.time', {
            opacity: 1,
            duration: 0.3,
        }, "<0.3")
        .to('.play', {
            opacity: 1,
            duration: 0.3
        },"<0.4")
        .to('.pause', {
            opacity: 1,
            duration: 0.3
        },"<0.4")
        .to('.speakers-tiny', {
            opacity: 1,
            duration: 0.3
        },"<0.4")
        .to('.go-back-up', {
            opacity: 1,
            duration: 0,
        }, "<0.5")
        .to('.helper', {
            opacity: 1,
            duration: 0.3,
        }, "<0.5") 

        exitLinkTimeline.play()
        
    }

    var linkDeactivteSequance = gsap.timeline()
    linkDeactivteSequance.add(linkItemBackToPlace.play())
                         .add(exitLinkAnimation(), "-=0.2")

   
    const activeLink = document.querySelector('.linkActive')

    removePlaceHolder(document.body)

    function removePlaceHolder(currentContainer) {
        setTimeout(() => {
            currentContainer.removeChild(activeLink)
            placeholder.parentElement.insertBefore(activeLink, placeholder)
            $('.linkActive').css({
                position: '',
                top: '',
                left: '',
                height: '',
                width: '',
                transform: ''
            })
            activeLink.classList = "word link"
        
            placeholder.parentElement.removeChild(placeholder)
        }, 1000);
    }
               
    return active = false
}

export {
    linkClick
}