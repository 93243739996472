const textContainer = document.querySelector('#con-container')
var scrollY
function changeWheelSpeed(container, speedY) {
	scrollY = 0;

	var handleScrollReset = function() {
		scrollY = container.scrollTop;
	};
	var handleMouseWheel = function(e) {
		e.preventDefault();
		scrollY += speedY * e.deltaY
		if (scrollY < 0) {
			scrollY = 0;
		} else {
			var limitY = container.scrollHeight - container.clientHeight;
			if (scrollY > limitY) {
				scrollY = limitY;
			}
		}
		container.scrollTop = scrollY;
	};

	container.addEventListener('mouseup', handleScrollReset, false);
	container.addEventListener('mousedown', handleScrollReset, false);
	container.addEventListener('mousewheel', handleMouseWheel, false);

	var removed = false;

	return function() {
		if (removed) {
			return;
		}

		container.removeEventListener('mouseup', handleScrollReset, false);
		container.removeEventListener('mousedown', handleScrollReset, false);
		container.removeEventListener('mousewheel', handleMouseWheel, false);

		removed = true;
	};

}



export {
    changeWheelSpeed,
}