import gsap from 'gsap'
import { plusToEx, exToPlus } from './Cursor'
import { Conversations } from './Info'
import { amount, audio, audioHandler, seekSlider, textContainer } from './InitPlayer'
import { speakerArray } from './SpeakersArray'

let speakerState = false

let speakerInfoTimeline = gsap.timeline({paused: true})
let speakerInfoTimeline2 = gsap.timeline({paused: true})




function speakerClick(i) {

    $(".word").removeClass('sub')

    const speakers = document.querySelectorAll('.speaker')

    speakers.forEach((speaker, thisIndex, array) => {
        //console.log(array.length)
        const speakerImgContainer = document.createElement('div')
        speakerImgContainer.classList = 'speaker-img-container'

        //speakerImgContainer.addEventListener('mouseover', exToPlus())
        //speakerImgContainer.addEventListener('mouseout', plusToEx())

        //console.log('i / CONVO is: ', i, 'index / SPEAKERS AMOUNT is: ', thisIndex)
        const speakerImg = document.createElement('img')
        speakerImg.src = speakerArray[i][thisIndex].img

        const speakerInfoContainer = document.createElement('div')
        speakerInfoContainer.classList = 'speaker-info-container'
        speakerInfoContainer.addEventListener('mouseover', () => {
            console.log('over')
            exToPlus()
        })
        speakerInfoContainer.addEventListener('mouseout', () => {
            $("#mousesvg").css({
                fill: '#000'
            })
            plusToEx()
        })

        const speakerInfoMain = document.createElement('div')
        speakerInfoMain.classList = 'speaker-info-main'

                    const speakerInfoMainRelatedTitle = document.createElement('div')
                    speakerInfoMainRelatedTitle.classList = 'speaker-info-related-title'
                    speakerInfoMainRelatedTitle.textContent = 'Related:'
                    speakerInfoMain.append(speakerInfoMainRelatedTitle)

        const speakerInfoSecond = document.createElement('div')
        speakerInfoSecond.classList = 'speaker-info-second'

               
                const occupationContainer = document.createElement('div') 
                occupationContainer.classList = 'speaker-info-occupation'

                speakerArray[i][thisIndex].occupation.forEach((occupation, index, array) => {
                    if (index < array.length-1) {
                        occupationContainer.textContent += occupation + ', '  
                    }
                    else {
                        occupationContainer.textContent += occupation + ' '  
                    }
                    
                });


                const wikiPage = document.createElement('div') 
                wikiPage.classList = 'wiki-page'
                wikiPage.textContent = 'Wikapedia Page'
                wikiPage.addEventListener('click', () => {
                    window.open(speakerArray[i][thisIndex].wikipedia_page, '_blank').focus();
                })

                const socialContainer = document.createElement('div') 
                socialContainer.classList = 'speaker-info-social'

                speakerArray[i][thisIndex].social_media.forEach((social, CurrIndex, array) => {
                    const socialItem = document.createElement('div') 
                    socialItem.classList = 'speaker-info-social-item'
                    socialItem.addEventListener('click', () => {
                        window.open(speakerArray[i][thisIndex].social_media[CurrIndex], '_blank').focus();
                    })
                    socialContainer.append(socialItem)
                })

                if (thisIndex < array.length/2) {

                    // Right IMG

                    $(speakerInfoSecond).css({
                        alignItems: "flex-end",
                        justifyContent: "flex-start"
                    })
                    $(occupationContainer).css({
                        justifyContent: "flex-end"
                    })
                    $(socialContainer).css({
                        alignItems: "flex-end",
                        justifyContent: "flex-end"
                    })
                    $(".speaker-info-social-item").css({
                        marginLeft: '1vw',
                        marginRight: '0vw'
                    })
                    $(wikiPage).css({
                        alignItems: "flex-end"
                    })

                }

                speakerArray[i][thisIndex].related.forEach((related, relatedIndex, parentArray) => {

                    const speakerinfoMainItemContainer = document.createElement('div')
                    speakerinfoMainItemContainer.classList = 'speaker-info-main-container'
    
                            const speakerinfoMainTitle = document.createElement('div')
                            speakerinfoMainTitle.classList = 'speaker-info-main-item speaker-info-main-title'
                            speakerinfoMainTitle.innerHTML = '<span class="info-main-title-span">' + related.title + '</span>'

                                speakerinfoMainTitle.addEventListener('click', () => {
                                    window.open(speakerArray[i][thisIndex].related[relatedIndex].link, '_blank').focus();
                                })

                            const speakerinfoMainLink = document.createElement('div')
                            speakerinfoMainLink.classList = 'speaker-info-main-item speaker-info-main-link'
                            speakerinfoMainLink.textContent = related.link

                            const speakerinfoMainSource = document.createElement('div')
                            speakerinfoMainSource.classList = 'speaker-info-main-item speaker-info-main-source'
                            speakerinfoMainSource.textContent = 'source: ' + related.source

                    speakerinfoMainItemContainer.append(
                        speakerinfoMainTitle,
                        speakerinfoMainLink,
                        speakerinfoMainSource
                    )

                    speakerInfoMain.append(speakerinfoMainItemContainer)
                })

                const speakerInfoMainBio = document.createElement('div')
                speakerInfoMainBio.classList = 'speaker-info-main-item speaker-info-main-bio'
                speakerInfoMainBio.textContent = speakerArray[i][thisIndex].bio

                speakerInfoSecond.append(
                    occupationContainer,
                    speakerInfoMainBio,
                    wikiPage,
                    socialContainer
                )
        


        speakerInfoContainer.append(speakerInfoMain, speakerInfoSecond)

        speakerImgContainer.append(speakerImg)

        let RightImgTimeline = gsap.timeline({paused: true})
        let LeftImgTimeline = gsap.timeline({paused: true})

        speaker.addEventListener('mouseup', () => {
            //console.log('index is: ' + index)

            $(".helper").css({
                transform: 'translateY(-10vh)',
            })

            $("#con-container").animate({scrollTop: 0}, 'slow')
            textContainer.scrollTop = 0
            audio.currentTime = 0
            //document.querySelector('#player').scrollIntoView({block: "center", inline: "center"})
            //console.log( textContainer.scrollTop)

            $(".speaker").css({
                opacity: 0
            })
            $("#player").css({
                PointerEvents: "none"
            })
            $("#chapters").css({
                display: "none",
                PointerEvents: "none"
            })
            $(speaker).css({
                //fontSize: '8vw',
                opacity: 1,
            })

            switch (speakerState) {

                // Open Click

                case false:

                   
                    $(".speaker").mouseover(plusToEx())
                    $(".speaker").mouseleave(plusToEx())

                    if (thisIndex < array.length/2) {

                        // Right IMG

                        $(speakerInfoContainer).css({
                            flexDirection: "row"
                        })
                        
                        $(speakerImgContainer).css({
                            justifyContent: "flex-end",
                            right: '10.5vw',
                            top: '50vh'
                        })
                       
                        $(speakerImgContainer).css({
                            clipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)"
                        })
                        $(speakerInfoContainer).css({
                            bottom: '50vh',
                            left: '10vw',
                            clipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)"
                        })
                        
                        RightImgTimeline = gsap.timeline()
                        RightImgTimeline.to(speakerImgContainer, {
                            clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
                            duration: 0.8,
                        })
                        .to(speakerInfoContainer, {
                            clipPath: "polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)",
                            duration: 0.8,
                        }, "<")
                        RightImgTimeline.play()

                    }
                    else {

                        // Left IMG

                        speakerInfoContainer.id = 'left-img-second-info'

                        $(speakerInfoContainer).css({
                            flexDirection: "row-reverse"
                        })

                        $(speakerImgContainer).css({
                            justifyContent: "flex-start",
                            left: '10.5vw',
                            bottom: '50vh'
                        })
                        $(speakerImgContainer).css({
                            clipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)"
                        })
                        $(speakerInfoContainer).css({
                            top: '50vh',
                            right: '10vw',
                            clipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)"
                        })

                        LeftImgTimeline = gsap.timeline()
                        LeftImgTimeline.to(speakerImgContainer, {
                            clipPath: 'polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)',
                            duration: 0.8,
                        })
                        .to(speakerInfoContainer, {
                            clipPath: 'polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)',
                            duration: 0.8,
                        }, "<")
                        LeftImgTimeline.play()
                    }

                    textContainer.append(speakerImgContainer, speakerInfoContainer)

                    speakerState = true
                    break;

                    // Close Click

                case true:
                    resetSpeakers()
                    
                    break;
            
                default:
                    break;
            }
            


        })

        function resetSpeakers() {
            if (speakerState) {
                function clearSpeakerInfo() {
                    textContainer.removeChild(speakerImgContainer)
                    textContainer.removeChild(speakerInfoContainer)

                }

                $(".helper").css({
                    transform: 'translateY(0vh)',
                })
                $("#player").css({
                    PointerEvents: "auto"
                })
                $("#chapters").css({
                    display: "block",
                    PointerEvents: "auto"
                })

                setTimeout(() => {
                    $(".speaker").css({
                        fontSize: 22/(Conversations[i].speakers.length) + 'vw',
                        opacity: 1
                    })
                }, 900);

                exToPlus()
                $(speaker).unbind()
            
                if (thisIndex < array.length/2) {
                    console.log('close right')
                    
                    RightImgTimeline.reverse()
                    setTimeout(() => {
                        clearSpeakerInfo
                    }, 900);
                        
                } else {
                    console.log('close left')
                    
                    //LeftImgTimeline = gsap.timeline({onComplete: clearSpeakerInfo})
                    LeftImgTimeline.reverse()
                    setTimeout(() => {
                        clearSpeakerInfo
                    }, 900);
                }

                $(speaker).css({
                    color: '#000'
                })

                

                speakerState = false
            }
        }
   });

}

export {
    speakerClick,

}