import './SCSS/index.scss'
import './JS/MouseHandler.js'
import './JS/initCon.js'
import './JS/ChangeWheelSpeed'
import './JS/popUp'
import './JS/LINKS'
import 'jquery'
import './JS/AxisView_AxisNames'
import { initCon } from './JS/initCon.js'
import { Intro } from './JS/Intro'
import gsap from 'gsap'

import logo from '../static/Material/logo.png'
import bz from '../static/Material/BZ_LOGO.png'

//initCon(0)

Intro()

document.querySelector('.logoimg').src = logo
document.querySelector('.bz-logo').src = bz


$(".intro-container").css({
    display: 'block',
    opacity: 0
})

$("#con-container").css({
    display: 'none'
})

$(".axis-container").css({
    display: 'none'
})

$(".about-container").css({
    display: 'none'
})

let aboutState =false
let aboutinitTimeline= gsap.timeline({paused: true})
        aboutinitTimeline
        .to('.about-container',  {
            display: 'block',
            opacity:  0,
            duration:  0,
        })
        .to(".about-container",{
            opacity: 1,
            duration: 0.6
        })

$(".menu").click(() => {
    if (!aboutState) {
        
        aboutinitTimeline.play()
        

        return aboutState = true;
    }
    else {
        aboutinitTimeline.reverse()

        return aboutState = false;
    }
})
