const linkArray = [

    //0
    [
        //Charles Murray
        [
            {
                title: 'A Violent Attack on Free Speech at Middlebury - The Atlantic',
                link: 'https://www.theatlantic.com/politics/archive/2017/03/middlebury-free-speech-violence/518667/',
                source: 'The Atlantic'
            },
    
            {
                title: 'Dozens of Middlebury Students Are Disciplined for Charles Murray Protest',
                link: 'https://www.nytimes.com/2017/05/24/us/middlebury-college-charles-murray-bell-curve.html',
                source: 'The New York Times'
            },
    
            {
                title: 'How the Middlebury Riot Really Went Down - Politico',
                link: 'https://www.politico.com/magazine/story/2017/05/28/how-donald-trump-caused-the-middlebury-melee-215195/',
                source: 'Politico'
            },
        ],

        // Middlebury

        [
            {
                title: 'Middlebury College protest against author Charles Murray',
                link: 'https://www.youtube.com/watch?v=utwrCc5cSRY',
                source: 'VTDigger'
            },
    
            {
                title: 'Video Recording of Charles Murray and Prof. Allison Stanger',
                link: 'https://www.middlebury.edu/announcements/news/2017/03/video-recording-charles-murray-and-prof-allison-stanger',
                source: 'Middlebury College Official Website'
            },
    
            {
                title: 'A conservative author tried to speak at a liberal arts college. He left fleeing an angry mob.',
                link: 'washingtonpost.com/news/grade-point/wp/2017/03/04/a-conservative-author-tried-to-speak-at-a-liberal-college-he-left-fleeing-an-angry-mob/',
                source: 'The Washington Post'
            },
        ],


        // Vox


        [
            {
                title: 'The Bell Curve is about policy. And it’s wrong.',
                link: 'https://www.vox.com/2018/4/10/17182692/bell-curve-charles-murray-policy-wrong',
                source: 'VOX'
            },
    
            {
                title: 'Vox.com | Ezra Klein | Talks at Google',
                link: 'https://www.youtube.com/watch?v=QPYbN9xrbTE',
                source: 'Google Talks'
            },
    
            {
                title: 'Ezra Klein on aligning journalism, politics, and what matters most',
                link: 'https://80000hours.org/podcast/episodes/ezra-klein-journalism-most-important-topics/',
                source: '80000 Hours'
            },
        ],


        // Turkhimer
        [
            {
                title: 'Charles Murray is once again peddling junk science about race and IQ',
                link: 'https://www.vox.com/the-big-idea/2017/5/18/15655638/charles-murray-race-iq-sam-harris-science-free-speech',
                source: 'VOX',
            },
    
            {
                title: 'Getting Voxed: Charles Murray, Ideology, and the Debate on IQ',
                link: 'https://quillette.com/2017/06/02/getting-voxed-charles-murray-ideology-science-iq/',
                source: 'Quillette',
            },
    
            {
                title: 'Genetics is the truther conspiracy of racial inequality',
                link: 'https://familyinequality.wordpress.com/2021/09/12/genetics-is-the-truther-conspiracy-of-racial-inequality/',
                source: 'Family Inequality',
            },
        ],

        // Harden

        [
            {
                title: 'The Twilight Of American Behavior Genetics',
                link: 'https://www.gnxp.com/WordPress/2022/04/06/the-twilight-of-american-behavior-genetics/',
                source: 'GNXP',
            },
    
            {
                title: 'Can Progressives Be Convinced That Genetics Matters?',
                link: 'https://www.newyorker.com/magazine/2021/09/13/can-progressives-be-convinced-that-genetics-matters',
                source: 'The New Yorker',
            },
    
            {
                title: '#212 - A CONVERSATION WITH KATHRYN PAIGE HARDEN',
                link: 'https://www.samharris.org/podcasts/making-sense-episodes/212-july-29-2020',
                source: 'Making Sense Podcast',
            },
        ],

        // Nisbit

        [
            {
                title: 'Get Smart - Book Review',
                link: 'https://www.nytimes.com/2009/03/29/books/review/Holt-t.html',
                source: 'The New York Times',
            },
    
            {
                title: 'What Does IQ Really Measure?',
                link: 'https://www.science.org/content/article/what-does-iq-really-measure',
                source: 'Science',
            },
    
            {
                title: 'There’s still no good reason to believe black-white IQ differences are due to genes',
                link: 'https://www.vox.com/the-big-idea/2017/6/15/15797120/race-black-white-iq-response-critics',
                source: 'VOX',
            },
        ],
    ],

    //1
    [
        //Charles Murray
        [
            {
                title: 'A Violent Attack on Free Speech at Middlebury - The Atlantic',
                link: 'https://www.theatlantic.com/politics/archive/2017/03/middlebury-free-speech-violence/518667/',
                source: 'The Atlantic'
            },
    
            {
                title: 'Dozens of Middlebury Students Are Disciplined for Charles Murray Protest',
                link: 'https://www.nytimes.com/2017/05/24/us/middlebury-college-charles-murray-bell-curve.html',
                source: 'The New York Times'
            },
    
            {
                title: 'How the Middlebury Riot Really Went Down - Politico',
                link: 'https://www.politico.com/magazine/story/2017/05/28/how-donald-trump-caused-the-middlebury-melee-215195/',
                source: 'Politico'
            },
        ],

        // Middlebury

        [
            {
                title: 'Middlebury College protest against author Charles Murray',
                link: 'https://www.youtube.com/watch?v=utwrCc5cSRY',
                source: 'VTDigger'
            },
    
            {
                title: 'Video Recording of Charles Murray and Prof. Allison Stanger',
                link: 'https://www.middlebury.edu/announcements/news/2017/03/video-recording-charles-murray-and-prof-allison-stanger',
                source: 'Middlebury College Official Website'
            },
    
            {
                title: 'A conservative author tried to speak at a liberal arts college. He left fleeing an angry mob.',
                link: 'washingtonpost.com/news/grade-point/wp/2017/03/04/a-conservative-author-tried-to-speak-at-a-liberal-college-he-left-fleeing-an-angry-mob/',
                source: 'The Washington Post'
            },
        ],


        // Vox


        [
            {
                title: 'The Bell Curve is about policy. And it’s wrong.',
                link: 'https://www.vox.com/2018/4/10/17182692/bell-curve-charles-murray-policy-wrong',
                source: 'VOX'
            },
    
            {
                title: 'Vox.com | Ezra Klein | Talks at Google',
                link: 'https://www.youtube.com/watch?v=QPYbN9xrbTE',
                source: 'Google Talks'
            },
    
            {
                title: 'Ezra Klein on aligning journalism, politics, and what matters most',
                link: 'https://80000hours.org/podcast/episodes/ezra-klein-journalism-most-important-topics/',
                source: '80000 Hours'
            },
        ],


        // Turkhimer
        [
            {
                title: 'Charles Murray is once again peddling junk science about race and IQ',
                link: 'https://www.vox.com/the-big-idea/2017/5/18/15655638/charles-murray-race-iq-sam-harris-science-free-speech',
                source: 'VOX',
            },
    
            {
                title: 'Getting Voxed: Charles Murray, Ideology, and the Debate on IQ',
                link: 'https://quillette.com/2017/06/02/getting-voxed-charles-murray-ideology-science-iq/',
                source: 'Quillette',
            },
    
            {
                title: 'Genetics is the truther conspiracy of racial inequality',
                link: 'https://familyinequality.wordpress.com/2021/09/12/genetics-is-the-truther-conspiracy-of-racial-inequality/',
                source: 'Family Inequality',
            },
        ],

        // Harden

        [
            {
                title: 'The Twilight Of American Behavior Genetics',
                link: 'https://www.gnxp.com/WordPress/2022/04/06/the-twilight-of-american-behavior-genetics/',
                source: 'GNXP',
            },
    
            {
                title: 'Can Progressives Be Convinced That Genetics Matters?',
                link: 'https://www.newyorker.com/magazine/2021/09/13/can-progressives-be-convinced-that-genetics-matters',
                source: 'The New Yorker',
            },
    
            {
                title: '#212 - A CONVERSATION WITH KATHRYN PAIGE HARDEN',
                link: 'https://www.samharris.org/podcasts/making-sense-episodes/212-july-29-2020',
                source: 'Making Sense Podcast',
            },
        ],

        // Nisbit

        [
            {
                title: 'Get Smart - Book Review',
                link: 'https://www.nytimes.com/2009/03/29/books/review/Holt-t.html',
                source: 'The New York Times',
            },
    
            {
                title: 'What Does IQ Really Measure?',
                link: 'https://www.science.org/content/article/what-does-iq-really-measure',
                source: 'Science',
            },
    
            {
                title: 'There’s still no good reason to believe black-white IQ differences are due to genes',
                link: 'https://www.vox.com/the-big-idea/2017/6/15/15797120/race-black-white-iq-response-critics',
                source: 'VOX',
            },
        ],
    ],

     //2
     [
        //Charles Murray
        [
            {
                title: 'A Violent Attack on Free Speech at Middlebury - The Atlantic',
                link: 'https://www.theatlantic.com/politics/archive/2017/03/middlebury-free-speech-violence/518667/',
                source: 'The Atlantic'
            },
    
            {
                title: 'Dozens of Middlebury Students Are Disciplined for Charles Murray Protest',
                link: 'https://www.nytimes.com/2017/05/24/us/middlebury-college-charles-murray-bell-curve.html',
                source: 'The New York Times'
            },
    
            {
                title: 'How the Middlebury Riot Really Went Down - Politico',
                link: 'https://www.politico.com/magazine/story/2017/05/28/how-donald-trump-caused-the-middlebury-melee-215195/',
                source: 'Politico'
            },
        ],

        // Middlebury

        [
            {
                title: 'Middlebury College protest against author Charles Murray',
                link: 'https://www.youtube.com/watch?v=utwrCc5cSRY',
                source: 'VTDigger'
            },
    
            {
                title: 'Video Recording of Charles Murray and Prof. Allison Stanger',
                link: 'https://www.middlebury.edu/announcements/news/2017/03/video-recording-charles-murray-and-prof-allison-stanger',
                source: 'Middlebury College Official Website'
            },
    
            {
                title: 'A conservative author tried to speak at a liberal arts college. He left fleeing an angry mob.',
                link: 'washingtonpost.com/news/grade-point/wp/2017/03/04/a-conservative-author-tried-to-speak-at-a-liberal-college-he-left-fleeing-an-angry-mob/',
                source: 'The Washington Post'
            },
        ],


        // Vox


        [
            {
                title: 'The Bell Curve is about policy. And it’s wrong.',
                link: 'https://www.vox.com/2018/4/10/17182692/bell-curve-charles-murray-policy-wrong',
                source: 'VOX'
            },
    
            {
                title: 'Vox.com | Ezra Klein | Talks at Google',
                link: 'https://www.youtube.com/watch?v=QPYbN9xrbTE',
                source: 'Google Talks'
            },
    
            {
                title: 'Ezra Klein on aligning journalism, politics, and what matters most',
                link: 'https://80000hours.org/podcast/episodes/ezra-klein-journalism-most-important-topics/',
                source: '80000 Hours'
            },
        ],


        // Turkhimer
        [
            {
                title: 'Charles Murray is once again peddling junk science about race and IQ',
                link: 'https://www.vox.com/the-big-idea/2017/5/18/15655638/charles-murray-race-iq-sam-harris-science-free-speech',
                source: 'VOX',
            },
    
            {
                title: 'Getting Voxed: Charles Murray, Ideology, and the Debate on IQ',
                link: 'https://quillette.com/2017/06/02/getting-voxed-charles-murray-ideology-science-iq/',
                source: 'Quillette',
            },
    
            {
                title: 'Genetics is the truther conspiracy of racial inequality',
                link: 'https://familyinequality.wordpress.com/2021/09/12/genetics-is-the-truther-conspiracy-of-racial-inequality/',
                source: 'Family Inequality',
            },
        ],

        // Harden

        [
            {
                title: 'The Twilight Of American Behavior Genetics',
                link: 'https://www.gnxp.com/WordPress/2022/04/06/the-twilight-of-american-behavior-genetics/',
                source: 'GNXP',
            },
    
            {
                title: 'Can Progressives Be Convinced That Genetics Matters?',
                link: 'https://www.newyorker.com/magazine/2021/09/13/can-progressives-be-convinced-that-genetics-matters',
                source: 'The New Yorker',
            },
    
            {
                title: '#212 - A CONVERSATION WITH KATHRYN PAIGE HARDEN',
                link: 'https://www.samharris.org/podcasts/making-sense-episodes/212-july-29-2020',
                source: 'Making Sense Podcast',
            },
        ],

        // Nisbit

        [
            {
                title: 'Get Smart - Book Review',
                link: 'https://www.nytimes.com/2009/03/29/books/review/Holt-t.html',
                source: 'The New York Times',
            },
    
            {
                title: 'What Does IQ Really Measure?',
                link: 'https://www.science.org/content/article/what-does-iq-really-measure',
                source: 'Science',
            },
    
            {
                title: 'There’s still no good reason to believe black-white IQ differences are due to genes',
                link: 'https://www.vox.com/the-big-idea/2017/6/15/15797120/race-black-white-iq-response-critics',
                source: 'VOX',
            },
        ],
    ],

     //3
     [
        //Charles Murray
        [
            {
                title: 'A Violent Attack on Free Speech at Middlebury - The Atlantic',
                link: 'https://www.theatlantic.com/politics/archive/2017/03/middlebury-free-speech-violence/518667/',
                source: 'The Atlantic'
            },
    
            {
                title: 'Dozens of Middlebury Students Are Disciplined for Charles Murray Protest',
                link: 'https://www.nytimes.com/2017/05/24/us/middlebury-college-charles-murray-bell-curve.html',
                source: 'The New York Times'
            },
    
            {
                title: 'How the Middlebury Riot Really Went Down - Politico',
                link: 'https://www.politico.com/magazine/story/2017/05/28/how-donald-trump-caused-the-middlebury-melee-215195/',
                source: 'Politico'
            },
        ],

        // Middlebury

        [
            {
                title: 'Middlebury College protest against author Charles Murray',
                link: 'https://www.youtube.com/watch?v=utwrCc5cSRY',
                source: 'VTDigger'
            },
    
            {
                title: 'Video Recording of Charles Murray and Prof. Allison Stanger',
                link: 'https://www.middlebury.edu/announcements/news/2017/03/video-recording-charles-murray-and-prof-allison-stanger',
                source: 'Middlebury College Official Website'
            },
    
            {
                title: 'A conservative author tried to speak at a liberal arts college. He left fleeing an angry mob.',
                link: 'washingtonpost.com/news/grade-point/wp/2017/03/04/a-conservative-author-tried-to-speak-at-a-liberal-college-he-left-fleeing-an-angry-mob/',
                source: 'The Washington Post'
            },
        ],


        // Vox


        [
            {
                title: 'The Bell Curve is about policy. And it’s wrong.',
                link: 'https://www.vox.com/2018/4/10/17182692/bell-curve-charles-murray-policy-wrong',
                source: 'VOX'
            },
    
            {
                title: 'Vox.com | Ezra Klein | Talks at Google',
                link: 'https://www.youtube.com/watch?v=QPYbN9xrbTE',
                source: 'Google Talks'
            },
    
            {
                title: 'Ezra Klein on aligning journalism, politics, and what matters most',
                link: 'https://80000hours.org/podcast/episodes/ezra-klein-journalism-most-important-topics/',
                source: '80000 Hours'
            },
        ],


        // Turkhimer
        [
            {
                title: 'Charles Murray is once again peddling junk science about race and IQ',
                link: 'https://www.vox.com/the-big-idea/2017/5/18/15655638/charles-murray-race-iq-sam-harris-science-free-speech',
                source: 'VOX',
            },
    
            {
                title: 'Getting Voxed: Charles Murray, Ideology, and the Debate on IQ',
                link: 'https://quillette.com/2017/06/02/getting-voxed-charles-murray-ideology-science-iq/',
                source: 'Quillette',
            },
    
            {
                title: 'Genetics is the truther conspiracy of racial inequality',
                link: 'https://familyinequality.wordpress.com/2021/09/12/genetics-is-the-truther-conspiracy-of-racial-inequality/',
                source: 'Family Inequality',
            },
        ],

        // Harden

        [
            {
                title: 'The Twilight Of American Behavior Genetics',
                link: 'https://www.gnxp.com/WordPress/2022/04/06/the-twilight-of-american-behavior-genetics/',
                source: 'GNXP',
            },
    
            {
                title: 'Can Progressives Be Convinced That Genetics Matters?',
                link: 'https://www.newyorker.com/magazine/2021/09/13/can-progressives-be-convinced-that-genetics-matters',
                source: 'The New Yorker',
            },
    
            {
                title: '#212 - A CONVERSATION WITH KATHRYN PAIGE HARDEN',
                link: 'https://www.samharris.org/podcasts/making-sense-episodes/212-july-29-2020',
                source: 'Making Sense Podcast',
            },
        ],

        // Nisbit

        [
            {
                title: 'Get Smart - Book Review',
                link: 'https://www.nytimes.com/2009/03/29/books/review/Holt-t.html',
                source: 'The New York Times',
            },
    
            {
                title: 'What Does IQ Really Measure?',
                link: 'https://www.science.org/content/article/what-does-iq-really-measure',
                source: 'Science',
            },
    
            {
                title: 'There’s still no good reason to believe black-white IQ differences are due to genes',
                link: 'https://www.vox.com/the-big-idea/2017/6/15/15797120/race-black-white-iq-response-critics',
                source: 'VOX',
            },
        ],
    ],

     //4
     [
        //Charles Murray
        [
            {
                title: 'A Violent Attack on Free Speech at Middlebury - The Atlantic',
                link: 'https://www.theatlantic.com/politics/archive/2017/03/middlebury-free-speech-violence/518667/',
                source: 'The Atlantic'
            },
    
            {
                title: 'Dozens of Middlebury Students Are Disciplined for Charles Murray Protest',
                link: 'https://www.nytimes.com/2017/05/24/us/middlebury-college-charles-murray-bell-curve.html',
                source: 'The New York Times'
            },
    
            {
                title: 'How the Middlebury Riot Really Went Down - Politico',
                link: 'https://www.politico.com/magazine/story/2017/05/28/how-donald-trump-caused-the-middlebury-melee-215195/',
                source: 'Politico'
            },
        ],

        // Middlebury

        [
            {
                title: 'Middlebury College protest against author Charles Murray',
                link: 'https://www.youtube.com/watch?v=utwrCc5cSRY',
                source: 'VTDigger'
            },
    
            {
                title: 'Video Recording of Charles Murray and Prof. Allison Stanger',
                link: 'https://www.middlebury.edu/announcements/news/2017/03/video-recording-charles-murray-and-prof-allison-stanger',
                source: 'Middlebury College Official Website'
            },
    
            {
                title: 'A conservative author tried to speak at a liberal arts college. He left fleeing an angry mob.',
                link: 'washingtonpost.com/news/grade-point/wp/2017/03/04/a-conservative-author-tried-to-speak-at-a-liberal-college-he-left-fleeing-an-angry-mob/',
                source: 'The Washington Post'
            },
        ],


        // Vox


        [
            {
                title: 'The Bell Curve is about policy. And it’s wrong.',
                link: 'https://www.vox.com/2018/4/10/17182692/bell-curve-charles-murray-policy-wrong',
                source: 'VOX'
            },
    
            {
                title: 'Vox.com | Ezra Klein | Talks at Google',
                link: 'https://www.youtube.com/watch?v=QPYbN9xrbTE',
                source: 'Google Talks'
            },
    
            {
                title: 'Ezra Klein on aligning journalism, politics, and what matters most',
                link: 'https://80000hours.org/podcast/episodes/ezra-klein-journalism-most-important-topics/',
                source: '80000 Hours'
            },
        ],


        // Turkhimer
        [
            {
                title: 'Charles Murray is once again peddling junk science about race and IQ',
                link: 'https://www.vox.com/the-big-idea/2017/5/18/15655638/charles-murray-race-iq-sam-harris-science-free-speech',
                source: 'VOX',
            },
    
            {
                title: 'Getting Voxed: Charles Murray, Ideology, and the Debate on IQ',
                link: 'https://quillette.com/2017/06/02/getting-voxed-charles-murray-ideology-science-iq/',
                source: 'Quillette',
            },
    
            {
                title: 'Genetics is the truther conspiracy of racial inequality',
                link: 'https://familyinequality.wordpress.com/2021/09/12/genetics-is-the-truther-conspiracy-of-racial-inequality/',
                source: 'Family Inequality',
            },
        ],

        // Harden

        [
            {
                title: 'The Twilight Of American Behavior Genetics',
                link: 'https://www.gnxp.com/WordPress/2022/04/06/the-twilight-of-american-behavior-genetics/',
                source: 'GNXP',
            },
    
            {
                title: 'Can Progressives Be Convinced That Genetics Matters?',
                link: 'https://www.newyorker.com/magazine/2021/09/13/can-progressives-be-convinced-that-genetics-matters',
                source: 'The New Yorker',
            },
    
            {
                title: '#212 - A CONVERSATION WITH KATHRYN PAIGE HARDEN',
                link: 'https://www.samharris.org/podcasts/making-sense-episodes/212-july-29-2020',
                source: 'Making Sense Podcast',
            },
        ],

        // Nisbit

        [
            {
                title: 'Get Smart - Book Review',
                link: 'https://www.nytimes.com/2009/03/29/books/review/Holt-t.html',
                source: 'The New York Times',
            },
    
            {
                title: 'What Does IQ Really Measure?',
                link: 'https://www.science.org/content/article/what-does-iq-really-measure',
                source: 'Science',
            },
    
            {
                title: 'There’s still no good reason to believe black-white IQ differences are due to genes',
                link: 'https://www.vox.com/the-big-idea/2017/6/15/15797120/race-black-white-iq-response-critics',
                source: 'VOX',
            },
        ],
    ],

     //5
     [
        //Charles Murray
        [
            {
                title: 'A Violent Attack on Free Speech at Middlebury - The Atlantic',
                link: 'https://www.theatlantic.com/politics/archive/2017/03/middlebury-free-speech-violence/518667/',
                source: 'The Atlantic'
            },
    
            {
                title: 'Dozens of Middlebury Students Are Disciplined for Charles Murray Protest',
                link: 'https://www.nytimes.com/2017/05/24/us/middlebury-college-charles-murray-bell-curve.html',
                source: 'The New York Times'
            },
    
            {
                title: 'How the Middlebury Riot Really Went Down - Politico',
                link: 'https://www.politico.com/magazine/story/2017/05/28/how-donald-trump-caused-the-middlebury-melee-215195/',
                source: 'Politico'
            },
        ],

        // Middlebury

        [
            {
                title: 'Middlebury College protest against author Charles Murray',
                link: 'https://www.youtube.com/watch?v=utwrCc5cSRY',
                source: 'VTDigger'
            },
    
            {
                title: 'Video Recording of Charles Murray and Prof. Allison Stanger',
                link: 'https://www.middlebury.edu/announcements/news/2017/03/video-recording-charles-murray-and-prof-allison-stanger',
                source: 'Middlebury College Official Website'
            },
    
            {
                title: 'A conservative author tried to speak at a liberal arts college. He left fleeing an angry mob.',
                link: 'washingtonpost.com/news/grade-point/wp/2017/03/04/a-conservative-author-tried-to-speak-at-a-liberal-college-he-left-fleeing-an-angry-mob/',
                source: 'The Washington Post'
            },
        ],


        // Vox


        [
            {
                title: 'The Bell Curve is about policy. And it’s wrong.',
                link: 'https://www.vox.com/2018/4/10/17182692/bell-curve-charles-murray-policy-wrong',
                source: 'VOX'
            },
    
            {
                title: 'Vox.com | Ezra Klein | Talks at Google',
                link: 'https://www.youtube.com/watch?v=QPYbN9xrbTE',
                source: 'Google Talks'
            },
    
            {
                title: 'Ezra Klein on aligning journalism, politics, and what matters most',
                link: 'https://80000hours.org/podcast/episodes/ezra-klein-journalism-most-important-topics/',
                source: '80000 Hours'
            },
        ],


        // Turkhimer
        [
            {
                title: 'Charles Murray is once again peddling junk science about race and IQ',
                link: 'https://www.vox.com/the-big-idea/2017/5/18/15655638/charles-murray-race-iq-sam-harris-science-free-speech',
                source: 'VOX',
            },
    
            {
                title: 'Getting Voxed: Charles Murray, Ideology, and the Debate on IQ',
                link: 'https://quillette.com/2017/06/02/getting-voxed-charles-murray-ideology-science-iq/',
                source: 'Quillette',
            },
    
            {
                title: 'Genetics is the truther conspiracy of racial inequality',
                link: 'https://familyinequality.wordpress.com/2021/09/12/genetics-is-the-truther-conspiracy-of-racial-inequality/',
                source: 'Family Inequality',
            },
        ],

        // Harden

        [
            {
                title: 'The Twilight Of American Behavior Genetics',
                link: 'https://www.gnxp.com/WordPress/2022/04/06/the-twilight-of-american-behavior-genetics/',
                source: 'GNXP',
            },
    
            {
                title: 'Can Progressives Be Convinced That Genetics Matters?',
                link: 'https://www.newyorker.com/magazine/2021/09/13/can-progressives-be-convinced-that-genetics-matters',
                source: 'The New Yorker',
            },
    
            {
                title: '#212 - A CONVERSATION WITH KATHRYN PAIGE HARDEN',
                link: 'https://www.samharris.org/podcasts/making-sense-episodes/212-july-29-2020',
                source: 'Making Sense Podcast',
            },
        ],

        // Nisbit

        [
            {
                title: 'Get Smart - Book Review',
                link: 'https://www.nytimes.com/2009/03/29/books/review/Holt-t.html',
                source: 'The New York Times',
            },
    
            {
                title: 'What Does IQ Really Measure?',
                link: 'https://www.science.org/content/article/what-does-iq-really-measure',
                source: 'Science',
            },
    
            {
                title: 'There’s still no good reason to believe black-white IQ differences are due to genes',
                link: 'https://www.vox.com/the-big-idea/2017/6/15/15797120/race-black-white-iq-response-critics',
                source: 'VOX',
            },
        ],
    ],

     //6
     [
        //Jas Waters
        [
            {
                title: 'AZIZ ANSARI IS A ROOKIE',
                link: 'https://www.youtube.com/watch?v=yiTI4JiT58o',
                source: 'The Andrew Schulz'
            },
    
            {
                title: 'FOR ANYONE THAT NEEDS IT…',
                link: 'https://jasfly.tumblr.com/',
                source: 'Jas Fly Blog'
            },
    
            {
                title: 'as Waters, journalist and TV writer for ‘This Is Us’ and ‘Kidding,’ dies at 39',
                link: 'https://www.latimes.com/entertainment-arts/tv/story/2020-06-10/jas-waters-dead-this-is-us-tv-writer',
                source: 'Los Angeles Times'
            },
        ],

     ],
    
]

export {
    linkArray
}