import { Conversations } from './Info'
import { exToPlus, plusToEx, plusToRead, readToPlus } from './Cursor';
import { pointer } from './MouseHandler'

const items = document.getElementsByClassName('item')
const coTitle = document.getElementsByClassName('item-title')
const itemsInfo = document.getElementById('con-info')
const itemInfoMain = document.getElementById('con-info-main')


let TypeSpeed = 50
let titleAppearCount = 0
let clearTitle = true 
let keepTitle = false
let raf = null
let currentCon;

function axisViewItems() {
    itemInfoMain.addEventListener('mouseenter', plusToRead)
    itemInfoMain.addEventListener('mouseleave', readToPlus)

    for (let i = 0; i < items.length; i++) {

        /*
            /////////////
            MOUSE OVER ITEM
            /////////////
        */

        items[i].addEventListener('mouseenter',itemHover)
        //itemsInfo.addEventListener('mouseenter', conInfo)
        items[i].addEventListener('mouseleave' , itemLeave) 

        function itemHover() {
            clearTimeout(raf)
            typeWriter()
            if (keepTitle) {
                plusToEx()
            }
        }
        
        function itemLeave() {
            cleanTitle()
            exToPlus()
        }
        

        function cleanTitle() {
            if (raf) {
                titleAppearCount = 0
                if (!keepTitle) {
                    coTitle[i].textContent = ''
                }
            }
        }

        function typeWriter() {
            if (titleAppearCount < Conversations[i].title.length) {
                if (!keepTitle) {
                    coTitle[i].textContent += Conversations[i].title.charAt(titleAppearCount);
                    titleAppearCount++;
                }
                raf = setTimeout(typeWriter, TypeSpeed);
            }
        }

        /*
        /////////////
        CLICK ON ITEM
        /////////////
        */

        items[i].addEventListener('click', () => {

            plusToEx()

            currentCon = i
            if(!keepTitle) {

                $('#tiles').css({
                    filter: 'blur(7px)'
                })

                coTitle[i].textContent = Conversations[i].title;

                clearTimeout(raf)
                switchTitles(i)
                showConInfo(i)
                keepTitle = true
                
            }
            else {
                clearTimeout(raf)
                switchTitles(i)
                hideConInfo()
                $('#tiles').css({
                    filter: 'blur(0px)'
                })
                clearTitles
                keepTitle = false
            }
        })
    }

    $(itemsInfo).css({
        top: items[0].getBoundingClientRect().top + items[0].clientHeight,
        left: items[0].getBoundingClientRect().left
    })

    function showConInfo(i) {

        $(itemsInfo).css({
            pointerEvents: 'auto',
            top: items[i].getBoundingClientRect().top + items[i].clientHeight,
            left: items[i].getBoundingClientRect().left
        })

        setTimeout(() => {
            $(itemsInfo).css({opacity: 1})
        }, 100);

        const infoMain = document.getElementById('con-info-main')
        infoMain.textContent = ''
        
        infoMain.innerHTML =  
        '<p>Summery:</p>' +
        '<br>' +
        '<p>' + Conversations[i].summery + '</p>' +
        '<br>'+ 
        '<p><span class="axis-info-seapkers-title">speakers:</span> ' + Conversations[i].speakers

        
    }

    function hideConInfo() {
        $(itemsInfo).css({opacity: 0})
        setTimeout(() => {
            $(itemsInfo).css({pointerEvents: 'none'})
        }, 300);
    }

    for (let i = 0; i < items.length; i++) {
        
    }

    var clearTitles = setTimeout(() => {
        for (let i = 0; i < coTitle.length; i++) {
            coTitle[i].textContent = ''
        }
    }, 1100);

    function switchTitles(i) {
        if (!keepTitle)
        {
            $('.item').css({filter: 'blur(10px)'})
            $(items[i]).css({opacity: 1, filter: 'blur(0px)'})
        }

        else $('.item').css({filter: 'blur(0px)'})
        
    }
}



function hide(elem, duration, delay) {
    setTimeout(() => {
        $(elem).animate({opacity: 0}, duration)
        setTimeout(() => {
            $(elem).css({display: 'none'})
        }, duration);
    }, delay);
}


function removeIndex() {
  
    console.log('click')

    
    clearTimeout(raf)
    $('.item').css({opacity: 1})
    scale()
    hideConInfo()
    $('#tiles').css({
        transform: 'rotateY(' + 0 + 'deg) rotateZ(' + 0 + 'deg)'
    })
    keepTitle = false

    setTimeout(() => {
        $('.yaxis>.line').animate({height: 0 + 'vh'}, 1300)
        hide($('.nom'), 300, 1300)
        hide($('.conse'), 300, 1600)
        setTimeout(() => {
            $('#tiles').css({
                transition: 'transform 1.5s ease',
                transform: 'rotateY(' + pointer.y*0 + 'deg) rotateZ(' + pointer.x*0 + 'deg) translate(-83vw, -45vh)'
            })
            changeMetaTag()
            changeInfraTag()
            $(".index-container").css({
                zIndex: 1
            })

            $(".transcript-container").css({
                zIndex: 2
            })
        }, 2000);
        for (let i = 0; i < items.length; i++) {
            setTimeout(() => {
                $(items[i]).css({opacity: 0})
            }, i*150);
        }

    }, 1000);
    
}

function changeMetaTag() {
    $("#metaTag").css({
        fontFamily: 'IBM Plex Mono'
    })
    $("#infraTag").css({
        fontFamily: 'IBM Plex Mono'
    })
    const metaTag = document.getElementById('metaTag')
    metaTag.textContent = '0:00'
}
function changeInfraTag() {
    const infraTag = document.getElementById('infraTag')
    infraTag.textContent = '121:04'
}
    

export {
    axisViewItems,
    keepTitle
}



letterWrapper(Conversations[0].summery)

function letterWrapper(text) {
    text.split(' ')
    //console.log(text)
}