import anime from 'animejs/lib/anime.es.js';

function plusToEx()
{
    $('.mouse').css({transform:'rotate(45deg) scale(0.75,0.75)'})
}

function exToPlus()
{
    $('.mouse').css({transform:'rotate(0deg) scale(0.75,0.75)'})
}

function plusToRead()
{
    $('.mouse').css({transform:'rotate(0deg) scale(0.75,0.75)'})
    anime({
        targets: '.mouse polygon',
        points: [
            {value: '48 33 ,48 31 ,33 31 ,33 19 ,33 19 ,33 16 ,33 16 ,31 16 ,31 16 ,31 19 ,31 19 ,31 31 ,17 31 ,17 33 ,31 33 ,31 44 ,31 44 ,31 48 ,31 48 ,33 48 ,33 48 ,33 44 ,33 44 ,33 33'},
            {value: '33 31 ,33 31 ,33 31 ,33 18 ,37 18 ,37 16 ,33 16 ,31 16 ,27 16 ,27 18 ,31 18 ,31 31 ,31 31 ,31 33 ,31 33 ,31 46 ,27 46 ,27 48 ,31 48 ,33 48 ,37 48 ,37 46 ,33 46 ,33 33'}
        ],
        easing: 'linear',
        duration: 400,
        delay: 0,
        loop: false
    });
}   

function plusToRead2()
{
    $('.mouse').css({transform:'rotate(0deg) scale(0.75,0.75)'})
    anime({
        targets: '.mouse polygon',
        points: [
            {value: '38 33 ,38 31 ,33 31 ,33 31 ,33 31 ,33 31 ,33 26 ,33 26 ,33 26 ,31 26 ,31 26 ,31 31 ,31 31 ,26 31 ,26 33 ,31 33 ,31 38 ,31 38 ,31 38 ,31 38 ,33 38 ,33 38 ,33 33 ,33 33 ,33 33'},
            {value: '33 31 ,33 31 ,33 31 ,33 18 ,37 18 ,37 16 ,33 16 ,31 16 ,27 16 ,27 18 ,31 18 ,31 31 ,31 31 ,31 33 ,31 33 ,31 46 ,27 46 ,27 48 ,31 48 ,33 48 ,37 48 ,37 46 ,33 46 ,33 33'}
        ],
        easing: 'linear',
        duration: 400,
        delay: 0,
        loop: false
    });
}   

function readToPlus()
{
    $('.mouse').css({transform:'rotate(0deg) scale(0.75,0.75)'})
    anime({
        targets: '.mouse polygon',
        points: [
        {value: '33 31 ,33 31 ,33 31 ,33 18 ,42 18 ,42 16 ,33 16 ,31 16 ,22 16 ,22 18 ,31 18 ,31 31 ,31 31 ,31 33 ,31 33 ,31 46 ,22 46 ,22 48 ,31 48 ,33 48 ,42 48 ,42 46 ,33 46 ,33 33'},
        {value: '48 33 ,48 31 ,33 31 ,33 19 ,33 19 ,33 16 ,33 16 ,31 16 ,31 16 ,31 19 ,31 19 ,31 31 ,17 31 ,17 33 ,31 33 ,31 44 ,31 44 ,31 48 ,31 48 ,33 48 ,33 48 ,33 44 ,33 44 ,33 33'}, 
        ],
        easing: 'linear',
        duration: 200,
        loop: false
    });
}

function readToPlus2()
{
    $('.mouse').css({transform:'rotate(0deg)'})
    anime({
        targets: '.mouse polygon',
        points: [
        {value: '33 31 ,33 31 ,33 31 ,33 18 ,42 18 ,42 16 ,33 16 ,31 16 ,22 16 ,22 18 ,31 18 ,31 31 ,31 31 ,31 33 ,31 33 ,31 46 ,22 46 ,22 48 ,31 48 ,33 48 ,42 48 ,42 46 ,33 46 ,33 33'},
        {value: '38 33 ,38 31 ,33 31 ,33 31 ,33 31 ,33 31 ,33 26 ,33 26 ,33 26 ,31 26 ,31 26 ,31 31 ,31 31 ,26 31 ,26 33 ,31 33 ,31 38 ,31 38 ,31 38 ,31 38 ,33 38 ,33 38 ,33 33 ,33 33'}, 
        ],
        easing: 'linear',
        duration: 200,
        loop: false
    });
}

export {
    plusToEx,
    exToPlus,
    plusToRead,
    readToPlus
}