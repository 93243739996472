import { pointer } from "./MouseHandler"

function pop_up(elem) {
    
    let popUpHeight = window.innerHeight/7
    let popUpWidth = window.innerWidth/7

    let elemTop = elem.getBoundingClientRect().top
    let elemLeft = elem.getBoundingClientRect().left

    const popContainer = document.createElement('div')
    popContainer.classList = 'pop-container'
    $(popContainer).css({
        position: 'absolute',
        top: elemTop,
        left: elemLeft,
        transform: 'translate(' + 0 +'px, ' + -popUpHeight + 'px)',
        height: popUpHeight,
        width: popUpWidth
    })
    document.body.append(popContainer)

    const svg_container = document.createElement('svg')
    svg_container.classList = 'pop-up-line'
    $(svg_container).css({
        position: 'absolute',
        height: '100%',
        width: '100%',
    })
    popContainer.append(svg_container)

    const popMain = document.createElement('div')
    popMain.classList = 'pop-main'
    $(popMain).css({
        top: '0px;',
        left: '0px',
        height: '80%;',
        width: '100%',
        background: '#000'
    })
}

const mouse = document.querySelector('#mouseId')
