

let mouse = {x: -1, y: -1}
let pointer = {x: -1, y: -1}
let xPercent;
let yPercent
function mouseHandler(e)
{
    mousemove(event)
}

function mousemove(event){
   
    pointer.x = ( event.clientX / window.innerWidth ) * 2 - 1;
	pointer.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

    mouse.x = event.pageX - 15
    mouse.y = event.pageY -15

    var xCord = event.clientX;
    var yCord = event.clientY;

    xPercent = xCord/window.innerWidth * 100;
    yPercent = yCord/window.innerHeight * 100;

    var transX = mouse.x
    var transY = mouse.y + 100
    //console.log(mouse)

    $('.mouse').css({
        top: mouse.y - 17,
        left: mouse.x - 15
    })

}

window.addEventListener('mousemove', mouseHandler);

export {
    mouse,
    pointer,
    xPercent,
    yPercent
}