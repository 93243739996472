import { words, spanIndex, transcriptContainer, data } from './initCon'
import { changeWheelSpeed } from './ChangeWheelSpeed'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollSmoother } from 'gsap/ScrollSmoother'
import 'jquery'
import { audioArray } from './AudioArray'

const textContainer = document.querySelector('#con-container')
const text = document.querySelector('#transcript-container')
const audioPlayerContainer = document.getElementById('player');
const seekSlider = document.getElementById('seek-slider');
const durationContainer = document.getElementById('duration');
const currentTimeContainer = document.getElementById('current-time');
const outputContainer = document.getElementsByClassName('volume-output');

let wordCount = 0;
let lastSeekerVal = 0;
let updateSubs;



let raf;
let currentSpan; 

$("#player").mouseenter(() => {
    $(".chapter").addClass("addChapter")
})
$("#player").mouseleave(() => {
    $(".chapter").removeClass("addChapter")
})

function initPlayer(index) {

    $("#player").css({display: 'flex'})

    // Play

    window.addEventListener('keyup', spacePress)

    textContainer.addEventListener("mousewheel", scrollHandler, false);
    changeWheelSpeed(textContainer, 0.01)


}

function appendConContainer() {
    let appendConContainerTimeline = gsap.timeline()
    appendConContainerTimeline
    .to("#con-container", {
        display: 'block',
        duration: 0
    })
    .to("#con-container", {
       opacity: 1,
        duration: 0.5
    })
    .to("#con-container", {
        display: 'block',
        duration: 0
    })
    .to("#player", {
        opacity: 1,
        duration: 0.5
    })
    .to(".pause", {
        opacity: 1,
    })
    .to(".play", {
        opacity: 1,
    })
    .to(".go-back-up", {
        opacity: 1,
    })
    .to(".speaker-tiny", {
        opacity: 1,
    })
}

let audio
let audioLoded = false

function loadAudio(thisIndex) {
    audio = audioArray[thisIndex] 
    console.log('audio is: ' + audio)
    console.log('audio duration: ' + audio.duration)
    
    audioLoded = true

    if (audio.readyState > 0) {
        displayDuration();
        setSliderMax();
        displayBufferedAmount();
    } else {
        audio.addEventListener('loadedmetadata', () => {
            displayDuration();
            setSliderMax();
            displayBufferedAmount();
        });
    }

    audio.addEventListener('progress', displayBufferedAmount);
    return audio
}

let playButtinIcon = gsap.timeline({paused: true})
playButtinIcon
.to('.play', {
    scale: '1.1, 1.1',
    duration: 0.1
    
})
.to('.play', {
    scale: '1, 1',
    duration: 0.1,
    
})


let pauseButtinIcon = gsap.timeline({paused: true})
playButtinIcon
.to('.pause', {
    scale: '1.1, 1.1',
    duration: 0.1
    
})
.to('.pause', {
    scale: '1, 1',
    duration: 0.1,
    
})

function audioHandler() {
    if (audioLoded) {
        // PLAY
        if (audio.paused) {
                console.log('on play amount is: ' + amount)
                //console.log('on play currentWord is: ' + amount)

                $(".speakers-tiny").css({transform: 'translateY(15vh)'})
                $(".go-back-up").css({opacity: '0'})

                audio.play()
                runText()
                requestAnimationFrame(whilePlaying)

                

                // Scroll To Transcript Top

                if (textContainer.scrollTop <= text.getBoundingClientRect().top) {

                    document.querySelector('.word').scrollIntoView({block: "center", inline: 'center', behavior: 'auto'})
                    
                    console.log('not in view')
                    amount = 0
                
                }   
    
        }

        // Pause
        else {

            $(".speakers-tiny").css({transform: 'translateY(0vh)'})
                $(".go-back-up").css({opacity: 1})
            console.log('pause')
            audio.pause()
            cancelAnimationFrame(raf)
            clearTimeout(runTimeOut)
        }
    }  
}

function spacePress(e)
{
    //e.preventDefault()
    
        if (e.keyCode === 32) {
            
            e.preventDefault() // Preventing Default
        }
}


const showRangeProgress = (rangeInput) => {
    if(rangeInput === seekSlider) audioPlayerContainer.style.setProperty('--seek-before-width', rangeInput.value / rangeInput.max * 100 + '%');
    else audioPlayerContainer.style.setProperty('--volume-before-width', rangeInput.value / rangeInput.max * 100 + '%');
}

seekSlider.addEventListener('input', (e) => {
    clearTimeout(updateSubs)
    showRangeProgress(e.target);
    SliderToCurrentSpan()

    currentSpan.scrollIntoView({block: "center", inline: "center"})

    currentTimeContainer.textContent = calculateTime(seekSlider.value);
    
    if(!audio.paused) {
        cancelAnimationFrame(raf);
    }
});



seekSlider.addEventListener('change', () => {
    SliderToCurrentSpan()
    let newSeekerVal = seekSlider.value
    if (newSeekerVal > lastSeekerVal) {
        updateSubs = setTimeout(() => {
            for (let j = 0; j < Math.round(seekSlider.value); j++) {
                if (words[j].start <= (seekSlider.value * 1000)) {
                    $(spanIndex[j]).addClass('sub')
                }

                /*
               if ( Math.round(words[j].start / 1000) <= Math.round(seekSlider.value) ) {
                   $(spanIndex[j]).addClass('sub')
               }
               */
            }
        }, 500);
    }
    else {
        
    }
    audio.currentTime = seekSlider.value;
    amount = seekSlider.value
    wordCount = seekSlider.value

    console.log('on change word Count is: ' + wordCount)
    console.log('on change current word is: ' + currentSpan.textContent)
    if(!audio.paused) {
        requestAnimationFrame(whilePlaying)
    }

    lastSeekerVal = newSeekerVal
});


const calculateTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${minutes}:${returnedSeconds}`;
}


const displayDuration = () => {
    durationContainer.textContent = calculateTime(audio.duration);
}

const setSliderMax = () => {
    seekSlider.max = Math.floor(audio.duration);
}

const displayBufferedAmount = () => {
    const bufferedAmount = Math.floor(audio.buffered);
    audioPlayerContainer.style.setProperty('--buffered-width', `${(bufferedAmount / seekSlider.max) * 100}%`);
}

const whilePlaying = () => {
    
    SliderToCurrentSpan()

    if ( Math.round(words[wordCount].start / 1000) === Math.round(audio.currentTime) ) {

            $(spanIndex[wordCount]).toggleClass('sub')
            wordCount++
        
    }

    
    const parargraphs = document.querySelectorAll('.para')
    parargraphs.forEach((para, index) => {
        let thisPara;
        let paraTop = para.getBoundingClientRect().top
        let paraBottom = para.getBoundingClientRect().top+para.getBoundingClientRect().height
            if (paraTop>window.innerHeight/2) {
                thisPara = index
                console.log('para is: ', thisPara)
            }
    });
   
    if ( Math.round(data.utterances[wordCount]) === Math.round(audio.currentTime) ) {

        $(spanIndex[wordCount]).toggleClass('sub')
        wordCount++
    
    }

    console.log('word count is: ' + wordCount)

    seekSlider.value = Math.floor(audio.currentTime);
    currentTimeContainer.textContent = calculateTime(seekSlider.value);
    audioPlayerContainer.style.setProperty('--seek-before-width', `${seekSlider.value / seekSlider.max * 100}%`);
    raf = requestAnimationFrame(whilePlaying);
}


let beforeCurrentSpan = []
function SliderToCurrentSpan()
{
    beforeCurrentSpan = []
    for (let i = 0; i < words.length; i++) {
        beforeCurrentSpan.push(spanIndex[i])
        if (words[i].start >= (seekSlider.value * 1000)) {
            currentSpan = spanIndex[i]
            
            //console.log(currentSpan)
            return currentSpan
        }
       
    }
}



//Scroll Functiniality

var timer = null;
const continueAnimation = () => {
    if(!audio.paused) {
        if(timer !== null) {
            clearTimeout(timer);        
        }
        timer = setTimeout(function() {
            requestAnimationFrame(whilePlaying)
        }, 250);
    }  
}

const scrollDirection = (evt) => {
    wheelDirection = evt.detail < 0 || evt.wheelDelta > 0 ? 1 : -1;
    //console.log(wheelDirection)
};

let amount = 0;
var wheelDirection = null

function scrollHandler(evt) {
    clearTimeout(checkSubs)
    cancelAnimationFrame(raf)
    scrollDirection(evt)
    ScrollFunction(evt)
    continueAnimation()
    
} 

gsap.registerPlugin(ScrollTrigger, ScrollSmoother)

function createScrollTrigger() {

    /*ScrollSmoother.create({
        wrapper: '#wrapper',
        content: '#content',
        //effects: true,
        //smooth: 0,
    })*/

    ScrollTrigger.create({
        trigger: text,
        scroller: textContainer,
        markers: true,
        start: 'top center',
        end: 'bottom top',
        onEnter: changeWheelSpeed(textContainer, 0.01)
    })
}

const ScrollFunction = (evt) => {      
    console.log('scroll happenning')
    if(text.getBoundingClientRect().top >= window.innerHeight/2) {
        $(".speakers-tiny").css({transform: 'translateY(15vh)'})
        $(".go-back-up").css({opacity: '0'})
        $('.word').removeClass('sub')
        wordCount = 0
        amount = 0
        audio.currentTime = 0;
        seekSlider.value = 0;
        clearTimeout(runTimeOut)

       
    }

    
    if (text.getBoundingClientRect().top < window.innerHeight/2) { 
        seekSlider.value = amount
        audio.currentTime = amount
        $(".speakers-tiny").css({transform: 'translateY(0vh)'}) 
        $(".go-back-up").css({opacity: '1'})
        
        //console.log('factor is: ' + factor)
        //amount =  factor + Math.round((textContainer.scrollTop) / ( text.getBoundingClientRect().height / audio.duration ) )
        //( text.getBoundingClientRect().height / audio.duration )
        amount = -43 + Math.round((textContainer.scrollTop) / ( text.getBoundingClientRect().height / audio.duration ) )
        //console.log(wheelDirection)
        if (wheelDirection<0) {
            if ( Math.round(words[wordCount].start / 1000) === Math.round(amount) ) {
                $(spanIndex[wordCount]).toggleClass("sub")
                wordCount++ 
            }
        }
        
        if (wheelDirection>0) {
            if ( Math.round(words[wordCount].start / 1000) >= Math.round(amount) ) {
                $(spanIndex[wordCount]).toggleClass("sub")
                wordCount--
            }
           
        }
        console.log('seeker value is: ' + amount)
        console.log('audio time is: ' + amount)
        console.log('word count is: ' + wordCount)
        console.log('amount is: ' + amount)
    }

   

    currentTimeContainer.textContent = calculateTime(amount);
    if (!audio.paused) {
        /*runText = setInterval(() => {
            textContainer.scrollBy({
                top: 0.5,
                behavior: 'smooth'
            })
        }, 50);*/
    }
    checkSubs
    return amount
}

let checkSubs = setTimeout(() => {
    for (let i = 0; i < wordCount; i++) {
        $(spanIndex[wordCount]).toggleClass("sub")
        if (wheelDirection<0) {
            wordCount++
        }
    }
}, 500);

let runTimeOut;
let scrollSpeed = 50
function runText() {

    textContainer.scrollBy({
        top: 0.5,  //spanIndex[wordCount].getBoundingClientRect().height/wordAmountInSentence,
        left: 0,
        behavior: 'smooth'
    })

    let currTop = spanIndex[wordCount].getBoundingClientRect().top

        // Slower

    if (currTop < (window.innerHeight/2 - 30)) {
        console.log('scroll speed is: ' + scrollSpeed)
        console.log('slower')
        scrollSpeed = scrollSpeed*1.25
        
        setTimeout(() => {
            scrollSpeed = 50 
        }, 1000);
    }
    if (currTop < (window.innerHeight/2 - 60)) {
        console.log('scroll speed is: ' + scrollSpeed)
        console.log('even slower')
        scrollSpeed = scrollSpeed*1.75
        
        setTimeout(() => {
            scrollSpeed = 50 
        }, 2000);
    }


    // Faster

    if (currTop > (window.innerHeight/2 + 30)) {
        console.log('scroll speed is: ' + scrollSpeed)
        console.log('faster')
        scrollSpeed = scrollSpeed/1.25
        
        setTimeout(() => {
            scrollSpeed = 50 
        }, 1000);
    }
    if (currTop > (window.innerHeight/2 + 60)) {
        console.log('scroll speed is: ' + scrollSpeed)
        console.log('even faster')
        scrollSpeed = scrollSpeed/1.75
        
        setTimeout(() => {
            scrollSpeed = 50 
        }, 1500);
    }

    console.log(currTop)
    
    runTimeOut = setTimeout(runText, scrollSpeed); 
}


$(".play").mouseup(() => {
    playButtinIcon.play()
    audioHandler()
})

$(".pause").click(() => {
    pauseButtinIcon.play()
    audioHandler()
})


export {
    initPlayer,
    seekSlider,
    audio,
    currentSpan,
    SliderToCurrentSpan,
    textContainer,
    currentTimeContainer,
    calculateTime,
    createScrollTrigger,
    text,
    amount,
    raf,
    loadAudio,
    audioHandler

}