const Conversations = [
        
        // 1

    {
        title: "Perils Of Dialects",
        time: "2H 04M",
        source: "Making Sense Podcast",
        topics: 'Philosophy of Science, Intellegance, Race',
        speakers: ["Sam Harris", "Ezra Klein",],
        summery: 
        "A conversation between the nuero-scientist and writer Sam Harris and journelist and co-founder of VOX Media Ezra Klein." +
        "Each defend theire position on truth. They seem to diagree about a" +
        "fundamental way the world is, and while it might be difficult to unravel any noise, you still " +
"       recieve two clear signals, one with a close attention to the current state whos pesimist view" +
        "admonishes and dictate from a place of grave concern. the second, is so saturated by the randomness" +
        "of humanity that it clings firmly to the solid objective truth of science."
    },
    // 2
    {
        title: "The Internet Doesn׳t Care",
        time: "59M",
        source: "Recode Decode",
        topics: 'Judicial Theory, Technology',
        speakers: ["Kara Swisher", "Jeff Kosseff", "Carrie Goldberg", "Mike Masnick"],
        summery: "Kara Swisher hosts a panel of legal experts and activists in the field of technological legislature and policy. They debate the vexing section 230 of the Communications Decency Act that exclude publishers of content from responsibility with respects to the content they publish. "
    },
    // 3
    {
        title: "Close Relationship",
        time: "52M",
        source: "Medium",
        topics: 'Feminism, Gender, Social Justice',
        speakers: ["Chrstopher Hitchens", "Neomi Wolf", "Rebecca Walker", "Tad Friend"],
        summery: "feminist figures and contrarians, discuss the changing times and the evolving relationship between the sexes. they discuss the climate in the Academic Space, the places of gender in society and the trends emerging in the modern era in relation to the scholarly notion of gender and the practicality of everyday life as a woman."
    },
    // 4
    {
        title: "The Eldest Proffesion",
        time: "67M",
        source: "92Y",
        topics: 'Storytelling, Writing, Social Theory',
        speakers: ["Malcolm Gladwell", "Michael Lewis"],
        summery: "Sometimes people get under your skin just by being, just because of who you think they are. In this conversation, it seems, that the causation is getting under some’s skin and the effect is probing even deeper. Malcolm Gladwell and Michael Lewis talk about why stories work they way they do and particularly about the success of Michael Lewis, querying about an underlying cause for the matter."
        
    },
    // 5
    {
        title: "A Classic Car Clash",
        time: "49M",
        source: "The Glenn Show",
        topics: 'Americal Politics, Epistemology, History',
        speakers: ["William F. Buckley", "Noam Chomsky"],
        summery: 
        "A conversation between the nuero-scientist and writer Sam Harris and journelist and co-founder of VOX Media Ezra Klein." +
        "Each defend theire position on truth. They seem to diagree about a" +
        "fundamental way the world is, and while it might be difficult to unravel any noise, you still " +
"       recieve two clear signals, one with a close attention to the current state whos pesimist view" +
        "admonishes and dictate from a place of grave concern. the second, is so saturated by the randomness" +
        "of humanity that it clings firmly to the solid objective truth of science."
    },
    // 6
    {
        title: "Compelled Freedom",
        time: "1H 18M",
        source: "Mindscape",
        topics: 'Statistics, Logic, Politics',
        speakers: ["Sean Carroll", "Jordan Ellenberg"],
        summery: "Any system in which politicians represent geographical districts with boundaries chosen by the politicians themselves is vulnerable to gerrymandering: carving up districts to increase the amount of seats that a given party is expected to win. But even fairly-drawn boundaries can end up quite complex, so how do we know that a given map is unfairly skewed? Math comes to the rescue. We can ask whether the likely outcome of a given map is very unusual within the set of all possible reasonable maps. That’s a hard math problem, however — the set of all possible maps is pretty big — so we have to be clever to solve it. I talk with geometer Jordan Ellenberg about how ideas like random walks and Markov chains help us judge the fairness of political boundaries."
    },
    // 7
    {
        title: "Writing In Circles",
        time: "1H 12M",
        source: "Whiting Wongs",
        topics: 'Writing, Screenwriting, Storytelling',
        speakers: ["Dan Harmon", "Jessica Wong"],
        summery: "Two writes discuss the reality of the writing room. The intricate power dynamics that are prevalent and the forces that are there but still go amiss. The reality of writers merge with their fiction and the totality is a mess of, well, what you watch on Television. Comedy and drama are as far apart as possible, each is its own genre with a distinct quality, but often they are mentioned alongside one another, seemingly coinciding, only a blurred border dividing the two. they talk about the the relation of these dynamics, the material, and immaterial as well."
    },
    
] 

const AltArray = [
    {
        title: "First Step For Everything",
        time: "2:18",
        source: "Medium",
        topics: 'Classical Philosophy, Epistemology',
        speakers: ["Agnes Callard", "Tyler Cowen"],
        summery: 
        "A conversation between the nuero-scientist and writer Sam Harris and journelist and co-founder of VOX Media Ezra Klein." +
        "Each defend theire position on truth. They seem to diagree about a" +
        "fundamental way the world is, and while it might be difficult to unravel any noise, you still " +
"       recieve two clear signals, one with a close attention to the current state whos pesimist view" +
        "admonishes and dictate from a place of grave concern. the second, is so saturated by the randomness" +
        "of humanity that it clings firmly to the solid objective truth of science."
    },
    {
        title: "The State Of Comedy",
        time: "2:18",
        source: "The Glenn Show",
        topics: 'Comedy, Race, Cancel Culture',
        speakers: ["Glenn Loury", "Roland Fryer", "Coleman Hughes", "Noam Dworman", "Andrew Shultz", "Shane Gillis", "T.J", "Judy Gold", "Rick Crom", "Nikki Jaz", "Sam Jay"],
        summery: 
        "A conversation between the nuero-scientist and writer Sam Harris and journelist and co-founder of VOX Media Ezra Klein." +
        "Each defend theire position on truth. They seem to diagree about a" +
        "fundamental way the world is, and while it might be difficult to unravel any noise, you still " +
"       recieve two clear signals, one with a close attention to the current state whos pesimist view" +
        "admonishes and dictate from a place of grave concern. the second, is so saturated by the randomness" +
        "of humanity that it clings firmly to the solid objective truth of science."
    },
]

export {
    Conversations
}