$(".meta").mouseenter(() => {
    $(".meta-info").css({
        opacity: 1
    })
})

$(".meta").mouseleave(() => {
    $(".meta-info").css({
        opacity: 0
    })
})

$(".infra").mouseenter(() => {
    $(".infra-info").css({
        opacity: 1
    })
})

$(".infra").mouseleave(() => {
    $(".infra-info").css({
        opacity: 0
    })
})

$(".nom").mouseenter(() => {
    $(".nom-info").css({
        opacity: 1
    })
})

$(".nom").mouseleave(() => {
    $(".nom-info").css({
        opacity: 0
    })
})

$(".conse").mouseenter(() => {
    $(".conse-info").css({
        opacity: 1
    })
})

$(".conse").mouseleave(() => {
    $(".conse-info").css({
        opacity: 0
    })
})