import gsap from "gsap";
import { Conversations } from "./Info";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { smoother } from "./Intro";
import { ConvoIMGArray } from "./ConvoIMGArray";
import { initCon } from "./initCon";
import { toggleViewMode } from "./ToggleViewMode";

let convoTabState = false
let lastIndex;
gsap.registerPlugin(ScrollTrigger)

function initIndex() {

    $(".line").css({
        opacity: 0
    })

    const indexContainer = document.querySelector('.intro-main')

    const divider = document.createElement('div')
    divider.classList = 'divider'

    const dividerNum1 = document.createElement('div')
    dividerNum1.classList = 'divider-num'
    dividerNum1.textContent = 1

    const dividerLine = document.createElement('div')
    dividerLine.classList = 'divider-line'

    const dividerNum2 = document.createElement('div')
    dividerNum2.classList = 'divider-num'
    dividerNum2.textContent = 1

    divider.append(dividerNum1, dividerLine, dividerNum2)

    indexContainer.append(divider)

    for (let i = 0; i < Conversations.length; i++) {
       
        const conversationItem = document.createElement('div')
        conversationItem.classList = 'conversation-item'
        conversationItem.id = 'conversation-item' + i

                // ITEM HEADER

                const header = document.createElement('div')
                header.classList = 'header'
                header.innerHTML = '<div class="conversation-item-title"><span class="conversation-item-span">' + Conversations[i].title + '</span></div>'

                conversationItem.append(header)


                // ITEM COVER

                const itemInfoCover = document.createElement('div')
                itemInfoCover.classList = 'item-info-cover'
                itemInfoCover.id = 'item-info-cover'+i

                            const runtime = document.createElement('div')
                            runtime.classList = 'item-runtime'
                            runtime.innerHTML = '<p class="item-summery-time">' + Conversations[i].time + '</p>'

                            const itemTopics = document.createElement('div')
                            itemTopics.classList = 'item-topics'
                            itemTopics.innerHTML = '<span class="item-topics-title">topics:</span> ' + Conversations[i].topics

                            const itemSpeakers = document.createElement('div')
                            itemSpeakers.classList = 'item-speakers'
                            itemSpeakers.innerHTML = '<span class="item-summery-title">speakers:</span> '

                            Conversations[i].speakers.forEach((speaker, index, array) => {
                                if (index < array.length-1) {
                                    itemSpeakers.innerHTML += speaker + ', '
                                }
                                else {
                                    itemSpeakers.innerHTML += speaker + ' '
                                }
                            })

                            

                            const itemIMG = document.createElement('img')
                            itemIMG.id = 'img'+i
                            itemIMG.src = ConvoIMGArray[i]

                            itemInfoCover.append(itemIMG, runtime, itemTopics, itemSpeakers)

                // ITEM BG 

                const itemInfoBG = document.createElement('div')
                itemInfoBG.classList = 'item-info-bg'
                itemInfoBG.id = 'item-info-bg'+i

                            const itemSummery = document.createElement('div')
                            itemSummery.classList = 'item-summery'
                            itemSummery.innerHTML = '<p class="item-summery-title">Summery:</p><br><p class="item-summery-text">' + Conversations[i].summery + '</p>'

                            

                            itemInfoBG.append(itemSummery)

                conversationItem.append(itemInfoCover, itemInfoBG)


        indexContainer.append(conversationItem)

       
        conversationItem.addEventListener('click', () => {

            console.log('item click')

            let clearIntroContainer = gsap.timeline({paused: true,})
              clearIntroContainer
                .to('.welcome>p:nth-child(1)', {
                    opacity: 0,
                    duration: 0.2
                })
                .to('.welcome>p:nth-child(2)', {
                    opacity: 0,
                    duration: 0.2
                }, "<+=0.1")
                .to('.welcome>p:nth-child(3)', {
                    opacity: 0,
                    duration: 0.2
                }, "<+=0.15")
                .to('.welcome>p:nth-child(4)', {
                    opacity: 0,
                    duration: 0.2
                }, "<+=0.2")
                .to('.welcome>p:nth-child(5)', {
                    opacity: 0,
                    duration: 0.2
                }, "<+=0.25")
                .to('.welcome>p:nth-child(6)', {
                    opacity: 0,
                    duration: 0.2
                }, "<+=0.3")
                .to('.divider-num', {
                    opacity: 0,
                    duration: 0.2
                }, "<+=0.4")
                .to('.conversation-item', {
                    opacity: 0,
                    duration: 0.3
                }, "<+=0.3")
                .to('.arrow', {
                    opacity: 0,
                    duration: 0.3
                }, "<")
                .to('.logo', {
                    scale: '0.6, 0.6',
                    y: '-3vh',
                    x: '11.5vw',
                    duration: 0.2
                }, "<+=0.5")
                .to('.intro-container', {
                    opacity: 0,
                    display: 'none',
                    duration: 0
                }, "<+=0.6")
                .to('.loading-line', {
                    opacity: 1,
                    duration: 0
                }, "<")
                


              

            var itemClickMaster = gsap.timeline({})
            itemClickMaster.add(clearIntroContainer.play())
                            .add(initCon(i), "+=1")
                         
                

        })
    }

    const items = document.getElementsByClassName('conversation-item')
    const itemsElem = document.querySelectorAll('.conversation-item')
    const titleSpans = document.getElementsByClassName('conversation-item-span')

    for (let i = 0; i < items.length; i++) {
        let revealAmount;
        let scroller = ScrollTrigger.create({
            scroller: '.intro-container',
            trigger: '#conversation-item'+i,
            start: 'top center',
            end: 'center center-=' + items[i].getBoundingClientRect().height/2,
            //markers: true,
            invalidateOnRefresh: true,
            //pin: true,
            
            onEnter: (self) => {
                document.querySelectorAll('.divider-num').forEach(num => {
                    num.textContent = i+1
                });
            },

            onLeave: () => {     
                $("#item-info-cover"+i).css({
                    clipPath: "polygon(0% " + Math.round(self.progress*100) +"%, 100% " + Math.round(self.progress*100) +"%, 100% 100%, 0% 100%)"
                })
            },
            onUpdate: (self) => {
                $("#item-info-cover"+i).css({
                    clipPath: "polygon(0% " + Math.round(self.progress*100) +"%, 100% " + Math.round(self.progress*100) +"%, 100% 100%, 0% 100%)"
                })
                //console.log("progress in " + i + ' is: ' , Math.round(self.progress*100) )
            },
            onEnterBack: (self) => {
                document.querySelectorAll('.divider-num').forEach(num => {
                    num.textContent = i+1
                });
            },
        })

        scroller

        
    }
}


document.querySelector('.toggle-view-link').addEventListener('click', toggleViewMode)
document.querySelector('.toggle-view-link').addEventListener('mouseover', () => {
   gsap.to('.toggle-view-button', {
       scale: '1.5, 1.5',
       duration: 0.1
   })
})

document.querySelector('.toggle-view-link').addEventListener('mouseout', () => {
    gsap.to('.toggle-view-button', {
        scale: '1, 1',
        duration: 0.1
    })
 })
document.querySelector('.toggle-view-button').addEventListener('click', toggleViewMode)
    
document.querySelector('.logo').addEventListener('mouseover', () => {
    gsap.to('.logo', {
        scale: '+=0.05',
        duration: 0.1
    })
})

    
document.querySelector('.logo').addEventListener('mouseout', () => {
    gsap.to('.logo', {
        scale: '-=0.05',
        duration: 0.1
    })
})



function rand(a, b) {
    return a + (b-a)*Math.random(); 
}

export {
    initIndex
}