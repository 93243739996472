import img0_0 from '../../static/SpeakersIMG/0/Sam_Harris.jpeg'
import img1_0 from '../../static/SpeakersIMG/0/Ezra_Klein.jpeg'

import img0_1 from '../../static/SpeakersIMG/1/KARA_SWISHER.jpeg'
import img1_1 from '../../static/SpeakersIMG/1/JEFF_KOSSEFF.jpeg'
import img2_1 from '../../static/SpeakersIMG/1/CARRIE_GOLDBERG.jpeg'
import img3_1 from '../../static/SpeakersIMG/1/MIKE_MASNICK.jpeg'

import img0_2 from '../../static/SpeakersIMG/2/CHRISTOPHER_HITCHENS.png'
import img1_2 from '../../static/SpeakersIMG/2/NEOMI_WOLF.jpeg'
import img2_2 from '../../static/SpeakersIMG/2/REBECCA_WALKER.jpeg'
import img3_2 from '../../static/SpeakersIMG/2/TAD_FRIEND.jpeg'

import img0_3 from '../../static/SpeakersIMG/3/MALCLOM_GLADWELL.png'
import img1_3 from '../../static/SpeakersIMG/3/MICHAEL_LEWIS.png'

import img0_4 from '../../static/SpeakersIMG/4/NOAM_CHOMSKY.jpeg'
import img1_4 from '../../static/SpeakersIMG/4/WILLIAM_F_BUCKLEY.jpeg'

import img0_5 from '../../static/SpeakersIMG/5/SEAN_CARROLL.jpg'
import img1_5 from '../../static/SpeakersIMG/5/JORDAN_ELLENBERG.jpeg'

import img0_6 from '../../static/SpeakersIMG/6/JESSICA_GAO.png'
import img1_6 from '../../static/SpeakersIMG/6/DAN_HARMON.png'

const speakerArray = [

    //1
    [

        //SAM Harris
        {
            name: 'Sam Harris',
            occupation: ['Nuero-Scientist', 'Author'],
            bio: 'American philosopher, author, and podcast host. His work touches on a wide range of topics, including rationality, religion, ethics, free will, neuroscience, meditation, psychedelics, philosophy of mind, politics, terrorism, and artificial intelligence. Harris came to prominence for his criticism of religion, and Islam in particular, and is known as one of the "Four Horsemen" of New Atheism, along with Richard Dawkins, Christopher Hitchens, and Daniel Dennett.',
            img: img0_0,
            wikipedia_page: 'https://en.wikipedia.org/wiki/Sam_Harris',
            social_media: [
                'https://www.samharris.org/', // Official Site
                'https://twitter.com/samharrisorg', // Twitter
                'https://www.youtube.com/channel/UCNAxrHudMfdzNi6NxruKPLw' // Youtube
            ],
            related: [
                {
                    title: 'Sam Harris, Charles Murray, and the allure of race science',
                    link: 'https://www.vox.com/policy-and-politics/2018/3/27/15695060/sam-harris-charles-murray-race-iq-forbidden-knowledge-podcast-bell-curve',
                    source: 'VOX'
                },

                {
                    title: '#73 — FORBIDDEN KNOWLEDGE',
                    link: 'https://www.samharris.org/podcasts/making-sense-episodes/73-forbidden-knowledge',
                    source: 'Waking Up'
                },

                {
                    title: 'In Defense of Charles Murray | Glenn Loury and Sam Harris | The Glenn Show',
                    link: 'https://www.youtube.com/watch?v=1UdKE2Hg19A',
                    source: 'The Glenn Show'
                },
            ],
        },

        //Ezra Klein
        {
            name: 'Ezra Klein',
            occupation: ['Journalist', 'Author'],
            bio: "American journalist, political analyst, New York Times columnist, and the host of The Ezra Klein Show podcast. He is a co-founder of Vox and formerly served as the website's editor-at-large.[1] He has held editorial positions at The Washington Post and The American Prospect, and was a regular contributor to Bloomberg News and MSNBC. His first book, Why We're Polarized, was published by Simon & Schuster in January 2020.",
            img: img1_0,
            wikipedia_page: 'https://en.wikipedia.org/wiki/Ezra_Klein',
            social_media: [
                'https://www.nytimes.com/by/ezra-klein', // Official Site
                'https://twitter.com/ezraklein', // Twitter
                //'https://www.youtube.com/channel/UCNAxrHudMfdzNi6NxruKPLw' // Youtube
            ],
            related: [
                {
                    title: 'Ezra Klein with Malcolm Gladwell: Why We’re Polarized',
                    link: 'https://www.youtube.com/watch?v=H1PA7mOhQ4Y',
                    source: '92Y'
                },

                {
                    title: 'Author Page | Editor at Large | Ezra Klein',
                    link: 'vox.com/authors/ezra-klein',
                    source: 'VOX'
                },

                {
                    title: 'Vox.com | Ezra Klein | Talks at Google',
                    link: 'https://www.youtube.com/watch?v=QPYbN9xrbTE',
                    source: 'Google'
                },
            ],
            
        }
    ],

    //2
    [

        //Kara Swisher
        {
            name: 'Kara Swisher',
            occupation: ['Journalist', 'Author'],
            bio: 'American journalist and author. She writet mostly about technology and has under her belt interviews with all of the tech giants. She is a contributing editor at New York, the host of the podcast Sway, and the co-host of the podcast Pivot. From 2018 to 2022, she was an opinion writer for The New York Times, before re-joining Vox Media.' ,
            img: img0_1,
            wikipedia_page: 'https://en.wikipedia.org/wiki/Kara_Swisher',
            social_media: [
                'https://www.nytimes.com/column/kara-swisher', // Official Site
                'https://twitter.com/karaswisher', // Twitter
            ],
            related: [
                {
                    title: 'Could the Internet Break Democracy?',
                    link: 'https://www.youtube.com/watch?v=yUqnvjsV1iQ',
                    source: 'NYU Law School'
                },

                {
                    title: 'Kara Swisher on Embracing Change and the Promise of Innovation | Standard Speaker Series',
                    link: 'https://www.youtube.com/watch?v=pMYNMF7Z7DU',
                    source: 'Standard Industries'
                },

                {
                    title: 'How Kara Swisher Is Holding Tech Titans Accountable | Success With Moira Forbes',
                    link: 'https://www.youtube.com/watch?v=h6njWMzGbNk',
                    source: 'Forbes Women'
                },
            ],
        },

        //Jeff Kosseff
        {
            name: 'Jeff Kosseff',
            occupation: ['Law Professor', 'Lawyer'],
            bio: "Associate Professor of Cybersecurity Law at the United States Naval Academy. He is the author of The United States of Anonymous: How the First Amendment Shaped Online Speech, published by Cornell University Press in 2022 and supported by an Andrew Carnetie Fellowship.  He also wrote Cybersecurity Law (Wiley), the first comprehensive textbook on U.S. cybersecurity laws and regulations, and in spring 2019 he published The Twenty-Six Words that Created the Internet (Cornell University Press), a nonfiction narrative history of Section 230 of the Communications Decency Act. ",
            img: img1_1,
            wikipedia_page: 'https://en.wikipedia.org/wiki/Jeff_Kosseff',
            social_media: [
                'https://www.jeffkosseff.com/', // Official Site
                'https://twitter.com/jkosseff', // Twitter
                //'https://www.youtube.com/channel/UCNAxrHudMfdzNi6NxruKPLw' // Youtube
            ],
            related: [
                {
                    title: 'What is Section 230 and Why Should I Care?',
                    link: 'https://www.youtube.com/watch?v=buq0k32GICk',
                    source: 'Freedom Forum'
                },

                {
                    title: 'WHY THE INTERNET’S MOST IMPORTANT LAW EXISTS AND HOW PEOPLE ARE STILL GETTING IT WRONG',
                    link: 'https://www.theverge.com/2019/6/21/18700605/section-230-internet-law-twenty-six-words-that-created-the-internet-jeff-kosseff-interview',
                    source: 'The Verge'
                },

                {
                    title: 'Vox.com | Ezra Klein | Talks at Google',
                    link: 'https://www.youtube.com/watch?v=QPYbN9xrbTE',
                    source: 'Youtube'
                },
            ],
            
        },

        //Carrie Goldberg
        {
            name: 'Carrie Goldberg',
            occupation: ['Lawyer'],
            bio: "merican lawyer specializing in sex crimes with her law firm C.A. Goldberg PLLC. She has represented: five clients who described sexual abuse committed by Harvey Weinstein; the former Democrat Member of Congress Katie Hill after her naked photos were published in the media; and the author Emma Cline after an ex-partner sued for plagiarism. Her legal cases with low-profile individuals—involving revenge porn, intimate partner violence and online abuse—often draw national media attention.",
            img: img2_1,
            wikipedia_page: 'https://en.wikipedia.org/wiki/Carrie_Goldberg',
            social_media: [
                'https://www.cagoldberglaw.com/', // Official Site
                'https://twitter.com/cagoldberglaw', // Twitter
                //'https://www.youtube.com/channel/UCNAxrHudMfdzNi6NxruKPLw' // Youtube
            ],
            related: [
                {
                    title: 'A PROPOSAL FOR SECTION 230 REFORM',
                    link: 'https://www.cagoldberglaw.com/proposal-for-section-230-reform/',
                    source: 'C.A.Goldberg'
                },

                {
                    title: 'Testimony of Carrie Goldberg Before the U.S. House of Representatives',
                    link: 'https://energycommerce.house.gov/sites/democrats.energycommerce.house.gov/files/documents/Witness%20Testimony_Goldberg_2021.12.01.pdf',
                    source: 'Committee on Energy and Commerce'
                },

                {
                    title: 'Carrie Goldberg battles sexual violence',
                    link: 'https://www.youtube.com/watch?v=gsvt8sJb9ks&t=32s',
                    source: 'Newsy'
                },
            ],
            
        },

        //Mike Masnick
        {
            name: 'Jeff Kosseff',
            occupation: ['Tech Journalist'],
            bio: "Mike Masnick is the founder & editor of the popular Techdirt blog as well as the founder of the Silicon Valley think tank, the Copia Institute.  In both roles, he explores the intersection of technology, innovation, policy, law, civil liberties, and economics. His writings have been cited by Congress and the EU Parliament.",
            img: img3_1,
            wikipedia_page: 'https://en.wikipedia.org/wiki/Jeff_Kosseff',
            social_media: [
                'https://www.techdirt.com/user/mmasnick/', // Official Site
                'https://twitter.com/mmasnick', // Twitter
                //'https://www.youtube.com/channel/UCNAxrHudMfdzNi6NxruKPLw' // Youtube
            ],
            related: [
                {
                    title: "Hello! You've Been Referred Here Because You're Wrong About Section 230 Of The Communications Decency Act",
                    link: 'https://www.techdirt.com/2020/06/23/hello-youve-been-referred-here-because-youre-wrong-about-section-230-communications-decency-act/',
                    source: 'techdirt'
                },

                {
                    title: ' In Defense of Section 230 and a Decentralized Internet',
                    link: 'https://reason.com/podcast/2021/01/13/mike-masnick-in-defense-of-section-230-and-a-decentralized-internet/',
                    source: 'reson'
                },

                {
                    title: 'Content Moderation Issues Online: Section 230 Is Not to Blame ',
                    link: 'https://scholarship.law.tamu.edu/cgi/viewcontent.cgi?article=1184&context=journal-of-property-law',
                    source: 'Texas A&M University'
                },
            ],
            
        },
    ],

    //3
    [

        //Christopher Hitchens
        {
            name: 'Christopher Hitchens',
            occupation: ['Journalist', 'Author'],
            bio: "British-American author and journalist who wrote or edited over 30 books (including five essay collections) on culture, politics, and literature. Hitchens originally described himself as a democratic socialist,[27] and he was a member of various socialist organisations throughout his life, including the International Socialists.[28] Hitchens eventually stopped describing himself as a socialist, but he continued to identify as a Marxist, supporting Marx's materialist conception of history." ,
            img: img0_2,
            wikipedia_page: 'https://en.wikipedia.org/wiki/Christopher_Hitchens',
            social_media: [
                'https://www.britannica.com/biography/Christopher-Hitchens', // Official Site
                //'https://twitter.com/karaswisher', // Twitter
            ],
            related: [
                {
                    title: 'Why Women Aren’t Funny',
                    link: 'https://www.vanityfair.com/culture/2007/01/hitchens200701',
                    source: 'Vanity Fair'
                },

                {
                    title: 'it remains the best description and most essential fact about al-Qaida.',
                    link: 'http://shorturl.at/dpD45',
                    source: 'Slate'
                },

                {
                    title: 'AS AMERICAN AS APPLE PIE',
                    link: 'https://www.vanityfair.com/news/2006/07/hitchens200607',
                    source: 'Vanity Fair'
                },
            ],
        },

        //Neomi Wolf
        {
            name: 'Neomi Wolf',
            occupation: ['Writer'],
            bio: "Following her first book The Beauty Myth (1991), she became a leading spokeswoman of what has been described as the third wave of the feminist movement. Feminists including Gloria Steinem and Betty Friedan praised her work. Others, including Camille Paglia, criticized it. In the 1990s, she was a political advisor to the presidential campaigns of Bill Clinton and Al Gore. Wolf's later books include the bestseller The End of America in 2007 and Vagina: A New Biography. Critics have challenged the quality and accuracy of the scholarship in her books; her serious misreading of court records for Outrages (2019) led to its US publication being cancelled. Wolf's career in journalism has included topics such as abortion and the Occupy Wall Street movement in articles for media outlets such as The Nation, The New Republic, The Guardian, and The Huffington Post.",
            img: img1_2,
            wikipedia_page: 'https://en.wikipedia.org/wiki/Naomi_Wolf',
            social_media: [
                //'https://www.jeffkosseff.com/', // Official Site
                //'https://twitter.com/jkosseff', // Twitter
                //'https://www.youtube.com/channel/UCNAxrHudMfdzNi6NxruKPLw' // Youtube
            ],
            related: [
                {
                    title: 'Naomi Wolf on a Closed Society',
                    link: 'https://www.youtube.com/watch?v=rSSMCIA65TM',
                    source: 'Big Think'
                },

                {
                    title: 'The Beuty Myth',
                    link: 'http://www.alaalsayid.com/ebooks/The-Beauty-Myth-Naomi-Wolf.pdf',
                    source: 'The Verge'
                },

                {
                    title: 'The Madness of Naomi Wolf',
                    link: 'https://newrepublic.com/article/162702/naomi-wolf-madness-feminist-icon-antivaxxer',
                    source: 'The New Republic'
                },
            ],
            
        },

        //Rebecca Walker
        {
            name: 'Rebecca Walker',
            occupation: ['Author'],
            bio: 'been regarded as one of the prominent voices of Third Wave Feminism, and the coiner of the term "third wave", since publishing a 1992 article on feminism in Ms. magazine called "Becoming the Third Wave", in which she proclaimed: "I am the Third Wave."',
            img: img2_2,
            wikipedia_page: 'https://en.wikipedia.org/wiki/Rebecca_Walker',
            social_media: [
                'https://www.rebeccawalker.com/', // Official Site
                'https://twitter.com/rebeccawalker', // Twitter
                //'https://www.youtube.com/channel/UCNAxrHudMfdzNi6NxruKPLw' // Youtube
            ],
            related: [
                {
                    title: 'Becoming the Third Wave',
                    link: 'https://heathengrrl.blogspot.com/2007/02/becoming-third-wave-by-rebecca-walker.html',
                    source: "Heathengrrl's Blog"
                },

                {
                    title: 'Anita Hill Woke Us Up',
                    link: 'https://www.huffpost.com/entry/anita-hill_b_1031311',
                    source: 'Huffington Post'
                },

                {
                    title: 'Lost love, identity and Donald Trump',
                    link: 'https://www.youtube.com/watch?v=d64bTVWSJwc',
                    source: 'France 24'
                },
            ],
            
        },

        //Tad Friend
        {
            name: 'Tad Friend',
            occupation: ['Writer'],
            bio: "Friend was a contributing editor at various publications, including Esquire, prior to becoming a staff writer at The New Yorker in 1998. His work there includes the magazine's 'Letter from California'. In 2001, he published 'Lost in Mongolia: Travels in Hollywood and Other Foreign Lands', a collection of his articles. His memoir, Cheerful Money: Me, My Family, and the Last Days of Wasp Splendor, was published in 2009.",
            img: img3_2,
            wikipedia_page: 'https://en.wikipedia.org/wiki/Tad_Friend',
            social_media: [
                'https://www.newyorker.com/contributors/tad-friend', // Official Site
                'https://twitter.com/tadfriend', // Twitter
                //'https://www.youtube.com/channel/UCNAxrHudMfdzNi6NxruKPLw' // Youtube
            ],
            related: [
                {
                    title: "YES.",
                    link: 'https://classic.esquire.com/article/1994/2/1/yes',
                    source: 'Esquire'
                },

                {
                    title: 'Can a Burger Help Solve Climate Change?',
                    link: 'https://www.newyorker.com/magazine/2019/09/30/can-a-burger-help-solve-climate-change',
                    source: 'reson'
                },

                {
                    title: 'BATTLE OF THE SEXES',
                    link: 'https://www.chicagotribune.com/news/ct-xpm-1994-03-20-9403200113-story.html',
                    source: 'The Chicago Tribune'
                },
            ],
            
        },
    ],

    //4 

    [
        //Malcolm Gladwell
        {
            name: 'Malclom Gladwell',
            occupation: ['Journalist', 'Author'],
            bio: "English-born Canadian journalist, author, and public speaker. He has been a staff writer for The New Yorker since 1996. He has published seven books: The Tipping Point: How Little Things Can Make a Big Difference (2000); Blink: The Power of Thinking Without Thinking (2005); Outliers: The Story of Success (2008); What the Dog Saw: And Other Adventures (2009), a collection of his journalism; David and Goliath: Underdogs, Misfits, and the Art of Battling Giants (2013); Talking To Strangers: What We Should Know about the People We Don't Know (2019) and The Bomber Mafia: A Dream, a Temptation, and the Longest Night of the Second World War (2021). His first five books were on The New York Times Best Seller list." ,
            img: img0_3,
            wikipedia_page: 'https://en.wikipedia.org/wiki/Malcolm_Gladwell',
            social_media: [
                'https://www.gladwellbooks.com/', // Official Site
                'https://twitter.com/gladwell', // Twitter
            ],
            related: [
                {
                    title: 'Storytelling: An Exchange',
                    link: 'https://www.theparisreview.org/miscellaneous/7039/storytelling-an-exchange-malcolm-gladwell-michael-lewis',
                    source: 'The Paris Review'
                },

                {
                    title: 'The Naked Face',
                    link: 'https://genius.com/Malcolm-gladwell-the-naked-face-annotated',
                    source: 'Genius'
                },

                {
                    title: 'Designs For Working',
                    link: 'http://www.netform.com/html/newyorker.pdf',
                    source: 'The New Yorker'
                },
            ],
        },

        //Michael Lewis
        {
            name: 'Michael Lewis',
            occupation: ['Writer'],
            bio: "American author and financial journalist. He has also been a contributing editor to Vanity Fair since 2009, writing mostly on business, finance, and economics. He is known for his nonfiction work, particularly his coverage of financial crises and behavioral finance.",
            img: img1_3,
            wikipedia_page: 'https://en.wikipedia.org/wiki/Michael_Lewis',
            social_media: [
                'http://michaellewiswrites.com/', // Official Site
                'https://twitter.com/jkosseff', // Twitter
                //'https://www.youtube.com/channel/UCNAxrHudMfdzNi6NxruKPLw' // Youtube
            ],
            related: [
                {
                    title: 'The Most Innovative Mind in Football',
                    link: 'https://www.youtube.com/watch?v=DU2YDfkkXRo',
                    source: '42 Analytics'
                },

                {
                    title: 'HOW TWO TRAILBLAZING PSYCHOLOGISTS TURNED THE WORLD OF DECISION SCIENCE UPSIDE DOWN',
                    link: 'https://www.vanityfair.com/news/2016/11/decision-science-daniel-kahneman-amos-tversky',
                    source: 'Vanity Fair'
                },

                {
                    title: 'the Art of Writing',
                    link: 'https://www.youtube.com/watch?v=LVMW__W6ulY',
                    source: 'UC Berkeley'
                },
            ],
            
        },
    ],

    //5 

    [
        //William F. Buckley Jr.
        {
            name: 'William F. Buckley Jr.',
            occupation: ['Diplomat', 'Author'],
            bio: "American public intellectual, conservative author and political commentator. In 1955, he founded National Review, the magazine that stimulated the conservative movement in the mid-20th century United States. Buckley hosted 1,429 episodes of the public affairs television show Firing Line (1966–1999), the longest-running public affairs show in American television history with a single host, where he became known for his distinctive Mid-Atlantic idiolect and wide vocabulary.",
            img: img1_4,
            wikipedia_page: 'https://en.wikipedia.org/wiki/William_F._Buckley_Jr.',
            social_media: [
                //'https://www.gladwellbooks.com/', // Official Site
                //'https://twitter.com/gladwell', // Twitter
            ],
            related: [
                {
                    title: 'Kennedy/Chappaquiddick; Nixon/Watergate, Part One',
                    link: 'https://www.nationalreview.com/2018/04/ted-kennedy-chappaquiddick-similarities-to-watergate-scandal/',
                    source: 'The National Review'
                },

                {
                    title: 'Kennedy/Chappaquiddick; Nixon/Watergate, Part Two',
                    link: 'https://www.nationalreview.com/2018/04/kennedy-chappaquiddick-nixon-watergate-part-two/',
                    source: 'The National Review'
                },

                {
                    title: 'MY RIGHT-WING CONSPIRACY',
                    link: 'https://www.newyorker.com/magazine/1996/10/21/my-right-wing-conspiracy',
                    source: 'The New Yorker'
                },
            ],
        },

        //Noam Chomsky
        {
            name: 'Noam Chomsky',
            occupation: ['Linguist'],
            bio: "American linguist, philosopher, cognitive scientist, historical essayist, social critic, and political activist. Sometimes called 'the father of modern linguistics', Chomsky is also a major figure in analytic philosophy and one of the founders of the field of cognitive science. He is a Laureate Professor of Linguistics at the University of Arizona and an Institute Professor Emeritus at the Massachusetts Institute of Technology (MIT), and is the author of more than 150 books on topics such as linguistics, war, politics, and mass media. Ideologically, he aligns with anarcho-syndicalism and libertarian socialism.",
            img: img0_4,
            wikipedia_page: 'https://en.wikipedia.org/wiki/Noam_Chomsky',
            social_media: [
                //'http://michaellewiswrites.com/', // Official Site
                //'https://twitter.com/jkosseff', // Twitter
                //'https://www.youtube.com/channel/UCNAxrHudMfdzNi6NxruKPLw' // Youtube
            ],
            related: [
                {
                    title: 'On human nature',
                    link: 'https://www.youtube.com/watch?v=3wfNl2L0Gf8&t=743s',
                    source: 'With France'
                },

                {
                    title: 'Moral Relativism and Michel Foucault',
                    link: 'https://www.youtube.com/watch?v=i63_kAw3WmE',
                    source: 'Youtube'
                },

                {
                    title: 'How Could Language Have Evolved?',
                    link: 'https://chomsky.info/20140826/',
                    source: 'chomsky.info'
                },
            ],
            
        },
    ],

    //6

    [
        //Sean Carroll
        {
            name: 'Sean Carroll',
            occupation: ['Theoretical Physicist', 'Philosopher'],
            bio: "Carroll received his Ph.D. from Harvard in 1993, and held positions at MIT, the Institute for Theoretical Physics at UC Santa Barbara, and the University of Chicago, before moving to Caltech in 2006. He has written a number of papers on dark matter, dark energy, and the origin of the universe. Recently, Carroll has worked on the foundations of quantum mechanics, the arrow of time, and the emergence of complexity. He also has interests in the intersection of science and the arts, the philosophy of physics, and implications of metaphysical naturalism." ,
            img: img0_5,
            wikipedia_page: 'https://en.wikipedia.org/wiki/Sean_M._Carroll',
            social_media: [
                'https://www.preposterousuniverse.com/', // Official Site
                'https://twitter.com/seanmcarroll', // Twitter
            ],
            related: [
                {
                    title: 'The Passage of Time & the Meaning of Life',
                    link: 'https://www.youtube.com/watch?v=7BH6XCRZad8',
                    source: 'Long Now Foundation'
                },

                {
                    title: 'Sean Carroll Thinks We All Exist on Multiple Worlds',
                    link: 'https://www.wired.com/story/sean-carroll-thinks-we-all-exist-on-multiple-worlds/',
                    source: 'Wired'
                },

                {
                    title: 'The Big Picture',
                    link: 'https://www.youtube.com/watch?v=x26a-ztpQs8',
                    source: 'Google'
                },
            ],
        },

        //Jordan Ellenberg
        {
            name: 'Jordan Ellenberg',
            occupation: ['Mathematician'],
            bio: "Jordan Ellenberg wrote a novel called The Grasshopper King, which came out in 2003 from Coffee House Press. Ellenberg also writes the “Do The Math” column in Slate, and has written articles on mathematical topics for The New York Times, The Washington Post, The Wall Street Journal, The Boston Globe, Wired and The Believer. Currently he is writing a book in the general vein of “mathematical ideas that are useful to people who don’t do math for a living,” called How Not To Be Wrong, which will be published by Penguin Press in 2014.",
            img: img1_5,
            wikipedia_page: 'https://en.wikipedia.org/wiki/Jordan_Ellenberg',
            social_media: [
                'https://www.jordanellenberg.com/', // Official Site
                'https://twitter.com/jsellenberg', // Twitter
                //'https://www.youtube.com/channel/UCNAxrHudMfdzNi6NxruKPLw' // Youtube
            ],
            related: [
                {
                    title: 'How Not to Be Misled by Data',
                    link: 'https://www.wsj.com/articles/how-not-to-be-misled-by-data-1435258933',
                    source: 'Wall Street Journal'
                },

                {
                    title: 'Five People. One Test. This Is How You Get There.',
                    link: 'https://www.nytimes.com/2020/05/07/opinion/coronavirus-group-testing.html',
                    source: 'The New York Times'
                },

                {
                    title: 'Mathematics of High-Dimensional Shapes and Geometries',
                    link: 'https://www.youtube.com/watch?v=tueAcSiiqYA',
                    source: 'Lex Fridman'
                },
            ],
            
        },
    ],

    //7

    [
        //Dan Harmon
        {
            name: 'Dan Harmon',
            occupation: ['Writer'],
            bio: "American writer, producer, and actor. He is best known as the creator and producer of the NBC/Yahoo! Screen sitcom Community (2009–2015), creator and host of the comedy podcast Harmontown (2012–2019), co-creator of the Adult Swim animated sitcom Rick and Morty (2013–present) and its subsequent franchise along with Justin Roiland, and co-founder of the alternative television network and website Channel 101 along with Rob Schrab." ,
            img: img1_6,
            wikipedia_page: 'https://en.wikipedia.org/wiki/Dan_Harmon',
            social_media: [
                //'https://www.preposterousuniverse.com/', // Official Site
                //'https://twitter.com/seanmcarroll', // Twitter
            ],
            related: [
                {
                    title: 'Story Structure 101: Super Basic Shit',
                    link: 'https://channel101.fandom.com/wiki/Story_Structure_101:_Super_Basic_Shit',
                    source: 'Channel101 Fandom'
                },

                {
                    title: 'Manspology',
                    link: 'https://danharmon.tumblr.com/',
                    source: 'Dan Harmon Poops'
                },

                {
                    title: 'Reddit AMA',
                    link: 'https://www.reddit.com/r/IAmA/comments/yne9x/i_am_dan_harmon_creator_of_community_writer_of/',
                    source: 'Reddit'
                },
            ],
        },

        //Jessica Gao
        {
            name: 'Jessica Gao',
            occupation: ['Writer'],
            bio: "American television writer and producer. She worked on the third season of Rick and Morty, writing the episode 'Pickle Rick', and wrote for Silicon Valley, Robot Chicken, The Mighty B!, Back at the Barnyard, Star Wars: Detours, and Kung Fu Panda: Legends of Awesomeness.",
            img: img0_6,
            wikipedia_page: 'https://en.wikipedia.org/wiki/Jessica_Gao',
            social_media: [
                //'https://www.jordanellenberg.com/', // Official Site
                //'https://twitter.com/jsellenberg', // Twitter
                //'https://www.youtube.com/channel/UCNAxrHudMfdzNi6NxruKPLw' // Youtube
            ],
            related: [
                {
                    title: '12 Things I Wish I Knew Before I Became a TV Writer',
                    link: 'https://www.cosmopolitan.com/entertainment/tv/a49602/things-i-wish-i-knew-before-i-became-a-tv-writer/',
                    source: 'Cosmopolitan'
                },

                {
                    title: 'Developing ABC Comedy Pitched As ‘Lazy Rich Asians',
                    link: 'https://www.vulture.com/2018/09/rick-and-morty-writer-jessica-gao-developing-abc-comedy.html',
                    source: 'Vulture'
                },

                {
                    title: 'An Interview by Jennifer Chen',
                    link: 'https://jchenwriter.com/jessica-gao/',
                    source: 'Jennifer Chen'
                },
            ],
            
        },
    ]

]

export {
    speakerArray
}