import { exToPlus, plusToEx, plusToRead, readToPlus } from "./Cursor";
import { amount, audio, currentSpan, initPlayer, seekSlider, SliderToCurrentSpan, textContainer, currentTimeContainer, calculateTime, initScroll, text, createScrollTrigger, raf, loadAudio } from "./InitPlayer";
import { linkClick } from "./LINKS";
import { Conversations } from "./Info";
import gsap from "gsap";
import { speakerClick, speakerImgContainer, speakerInfoContainer } from "./speakerClick";
import { changeWheelSpeed } from "./ChangeWheelSpeed";
import 'jquery'
import { dataArray } from "./DataArray";

const initButton = document.getElementById('playCon')
const transcriptContainer = document.getElementById('transcript-container')


let data;
const words = [] // the complete array of words
var spanIndex = [] //an array of all the spans

//initButton.addEventListener('click', initCon)


// INIT Funtion



// INIT Funtion
let speaker;

async function initCon(index) {

    ConversationState = true

    $(".intro-container").css({
        display: 'none'
    })

    $(".axis-container").css({
        display: 'none'
    })

    $("#con-container").css({
        display: 'block'
    })

    $(".toggle-view-button").css({
       opacity: 0,
       pointerEvents: 'none'
    })

    $("body").css({
        overflow: '',
        height: ''
    })

    loadAudio(index)
    loadData(index)
    setTimeout(() => {
        Transcript(index)
        convoHeader(index)
        initPlayer(index)
        //createScrollTrigger()
        initChapters()
        linkClick(index)
        goBackUp()
        speakerClick(index)
        conHoverHandler()
        initConversation()
        initTinyPlayers(index)
        document.querySelector('.logo').addEventListener('click', () => {
            logoClick()
        })
    }, 100);
}

function loadData(index) {
    console.log('data index is: ', index)
    data = dataArray[index]
}



const parasStartStamp = []
const parasEndStamp = []
let ParaCount = 0;

function conHoverHandler() {
    $('p').mouseenter(() => {
        plusToRead()
    })
    $('p').mouseleave(() => {
        readToPlus()
    })
    
}


function goBackUp() {
    $(".go-back-up").mouseup(() => {
        //amount = 0
        audio.currentTime = 0
        seekSlider.value = 0

        textContainer.scrollBy({
        top: -(textContainer.scrollTop - textContainer.getBoundingClientRect().y),
        left: 0,
        //behavior: 'smooth'
        })
    })
}

const tinySpeakerContainer = document.querySelector('.speakers-tiny')
function initTinyPlayers(ParentIndex) {

    Conversations[ParentIndex].speakers.forEach((tiny, ThisIndex, Array) => {
        const tinySpeaker = document.createElement('div')
        tinySpeaker.classList = 'tiny'
        tinySpeaker.id = 'tiny_' + ParentIndex + '_' + ThisIndex
        tinySpeaker.textContent = tiny
        $(tinySpeaker).css({
            transform: 'translateX(' + 10*ThisIndex + 'px)'
        })

        tinySpeakerContainer.append(tinySpeaker)
    });
}

function convoHeader(index) {
    let indexNum = index
    //.log(window.innerWidth)
    const speakersContainer = document.querySelector('.speakers')
    //console.log(speakersContainer.offsetWidth)
    for (let i = 0; i < Conversations[indexNum].speakers.length; i++) {
        speaker = document.createElement('div')
        speaker.classList = 'speaker speaker' + indexNum + '_' + i
        speaker.textContent = Conversations[index].speakers[i]
        $(speaker).css({
            fontSize: 22/(Conversations[indexNum].speakers.length) + 'vw',
            transform: 'translateX(' + (speakersContainer.getBoundingClientRect().width/Conversations[indexNum].speakers.length)*(i) + 'px)'
        })
        
        document.querySelector('#convo-header>.speakers').append(speaker)
    }
}
let linkCount = 0
function Transcript(ParentIndex) {
    linkCount = 0
    if (data) {
        console.log(data)

        console.log('in transcript parentIndex is: ', ParentIndex)

        // Create Paragraphs from number of utterances in

        for (let i = 0; i < data.utterances.length; i++) {
            
            parasStartStamp.push(data.utterances[i].start)
            parasEndStamp.push(data.utterances[i].end)

            const para = document.createElement('p')
            //para.addEventListener('mouseenter', plusToRead())
            //para.addEventListener('mouseleave', readToPlus())
            para.classList = 'para'
            para.id = 'para_' + ParentIndex + '_' + i

            // Mark Link
           
           
            for (let q = 0; q < data.utterances[i].words.length; q++) {
                
                const word = document.createElement('span')
                word.classList = 'word' // initWord'
                word.textContent = data.utterances[i].words[q].text + ' '

                words.push({
                    word: data.utterances[i].words[q].text,
                    start: data.utterances[i].words[q].start,
                    speaker: data.utterances[i].words[q].speaker, 
                    end: data.utterances[i].words[q].end
                }) 

                //console.log('para is: ', i)
                //console.log('word is: ', q)
                //console.log('word start is: ', data.utterances[i].words[q].start)
                //console.log('link count is: ', linkCount)
                //console.log('link start is: ', data.entities[linkCount].start)

                if (data.utterances[i].words[q].start == data.entities[linkCount].start) {

                    if (data.entities[linkCount].entity_type === 'person_name') {
                        if (data.entities[linkCount].text.match(' ')) {
                            word.textContent += ' ' + data.utterances[i].words[q+1].text + ' '
                            word.classList += ' link'
                            if (linkCount < data.entities.length-1) {
                                linkCount++
                            }
                            else {
                                linkCount = 0
                            }      
                           
                        }
                        else {
                            if (linkCount < data.entities.length-1) {
                                linkCount++
                            }
                            else {
                                linkCount = 0
                            }      
                        }
                        
                    }
                    else if (data.entities[linkCount].entity_type === 'occupation') {
                        linkCount++ 
                    }
                    else if (data.entities[linkCount].entity_type === 'organization') {
                        if (data.entities[linkCount].text.match(' ')) {
                            word.textContent += ' ' + data.utterances[i].words[q+1].text + ' '
                            word.classList += ' link'
                            if (linkCount < data.entities.length-1) {
                                linkCount++
                            }
                            else {
                                linkCount = 0
                            }      
                            
                        }
                        else {
                            word.classList += ' link'
                            if (linkCount < data.entities.length-1) {
                                linkCount++
                            }
                            else {
                                linkCount = 0
                            }      
                        }
                    }  

                    
                    
                }
                para.append(word)
                
            }
            transcriptContainer.append(para)
           
            //ParaCount++
        }

        //console.log(words)
        spanIndex = document.getElementsByClassName('word')
    }
}



function initChapters() {

    let chapterLocFactor = seekSlider.getBoundingClientRect().width/audio.duration
    const chapterContainer = document.getElementById('chapters')
    $(chapterContainer).css({
        top: seekSlider.getBoundingClientRect().top,
        left: seekSlider.getBoundingClientRect().left,
        width: seekSlider.getBoundingClientRect().width
    })
    for (let i = 0; i < data.chapters.length; i++) {
        const chapter = document.createElement('div')
        chapter.classList = 'chapter'

            const chapterNameWrapper = document.createElement('div')
            chapterNameWrapper.classList = 'chapterName'
            chapterNameWrapper.textContent = 'chapter ' + (i + 1)

            chapter.append(chapterNameWrapper)

        $(chapter).css({
            left:(Math.round(data.chapters[i].start/1000)*chapterLocFactor),
            animationDelay: '0.' + i + 's',
        })
        chapter.addEventListener('mouseup', () => { 

            audio.currentTime = Math.round(data.chapters[i].start/1000)
            seekSlider.value = audio.currentTime
            currentTimeContainer.textContent = calculateTime(seekSlider.value);
            
            SliderToCurrentSpan()

            currentSpan.scrollIntoView({block: "center", inline: 'center',})

            /*
            textContainer.scrollTo({
                top: currentSpan.getBoundingClientRect().top,
                left: 0,
                behavior: 'smooth'
            })
            */
            //currentSpan.scrollIntoView({block: "center", inline: "center"})
        })

        chapter.addEventListener('mouseover', () => {
            $("#mousesvg").css({
                fill: 'rgb(35, 60, 246)'
            })
        })

        chapter.addEventListener('mouseleave', () => {
            $("#mousesvg").css({
                fill: '#000'
            })
        })

        const chapterSummary = document.createElement('div')
        chapterSummary.classList = 'chapter-summary'
        const para = document.createElement('p')

        para.addEventListener('mouseenter', plusToRead)
        para.addEventListener('mouseout', readToPlus)

        const header = document.createElement('p')
        header.textContent = 'Summery: '
        para.textContent = data.chapters[i].summary
        
        chapterSummary.append(header, para)
        
        let typeSpeed = 50
        let typeCount = 0

        chapter.addEventListener('mouseover', (e) => {

           if (e.target.getBoundingClientRect().left <= window.innerWidth/2) {
           
                let chapterTimeline = gsap.timeline()
                chapterTimeline.to(chapter, {
                    height: "auto",
                    width: "27vw",
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    duration: 0,
                    
                    function() {
                        chapter.append(chapterSummary)
                    },
                    zIndex: 10,
                })
                .to(chapterSummary, {
                    opacity: 1,
                    duration: 0.5,
                    
                })
                .to('.chapterName', {
                    marginLeft: '0.5vw',
                    marginTop: '1vh',
                    marginBottom: '2vh'
                }, "<")
           }

           else {
               
                let chapterTimeline = gsap.timeline()
                chapterTimeline.to(chapter, {
                    height: "auto",
                    width: "27vw",
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    duration: 0,
                    function() {
                        chapter.append(chapterSummary)
                    },
                    translateX: -chapter.offsetWidth,
                    zIndex: 10,
                })
                .to(chapterSummary, {
                    opacity: 1,
                    duration: 0.5,
                    
                })
                .to('.chapterName', {
                    marginLeft: '0.5vw',
                    marginTop: '1vh',
                    marginBottom: '2vh'
                }, "<") 
           }
            

            
            
           
        })

        chapter.addEventListener('mouseleave', () => {

            chapter.removeChild(chapterSummary)
            let chapterTimeline = gsap.timeline()
            chapterTimeline.to(chapter, {
                height: "3vh",
                width: "7vw",
                alignItems: 'center',
                justifyContent: 'center',
                duration: 0.5,
                zIndex: '',
                marginLeft: '0vw',
                translateX: 0,
               
            })
            .to('.chapterName', {
                marginLeft: '0vw',
                marginTop: '0vh',
                marginBottom: '0vh',
                
            })
            
             
            
        })

        chapterContainer.append(chapter)   
    }
}

function helper() {
    gsap.from('.helper', {
        opacity: 0,
        duration: 1,
    })
}

helper()


function checkForGoUp() {
    if (textContainer.scrollTop > (text.getBoundingClientRect().height()/2 + window.innerHeight)) {
        
    }
}


function initConversation() {
    let initConversationAnimation = gsap.timeline({delay:1, paused: true})
    initConversationAnimation
    
    .to('.loading-line', {
        width: "83.25vw",
        duration: 0.2
    })
    .to('.play', {
        opacity: 1,
        duration: 0.2,
    }, "<")
    .to(".pause", {
        opacity: 1,
        duration: 0.2,
    })
    .to('.speaker', {
        opacity: 1,
        duration: 0.2,
    }, "<")
    .to('#player', {
        opacity: 1,
        duration: 0.2,
    }, "<")
    .to('.menu', {
        opacity: 0,
        pointerEvents: 'none',
        duration: 0.2,
    }, "<")
    .to('#transcript-container', {
        opacity: 1,
        duration: 0.2,
    }, "<")
    .to('.loading-line', { // DELETE AFTER
        opacity: 1,
        duration: 0.2
    })
    

    initConversationAnimation.play()
}

let ConversationState

function logoClick() {
    if (ConversationState) {
        console.log('go back home from convo')

        audio.pause()

        let logoClickAnimation = gsap.timeline({delay:1, paused: true})
        logoClickAnimation
        .to('.loading-line', {
            width: "83.25vw",
            duration: 0.2
        })
        .to('.play', {
            opacity: 0,
            duration: 0.2,
        }, "<")
        .to('.pause', {
            opacity: 0,
            duration: 0.2,
        }, "<")
        .to(".go-back-up", {
            opacity: 0,
            duration: 0.2,
        }, "<")
        .to('.speaker', {
            opacity: 0,
            duration: 0.2,
        }, "<")
        .to('#player', {
            opacity: 0,
            duration: 0.2,
        }, "<")
        .to('.menu', {
            opacity: 1,
            pointerEvents: 'auto',
            duration: 0.2,
        }, "<")
        .to('#transcript-container', {
            opacity: 0,
            duration: 0.2,
        }, "<")
        .to('.loading-line', { // DELETE AFTER
            opacity: 1,
            duration: 0.2
        })
        .to('#con-container', {
            display: 'none',
            duration: 0
        })
        .to('.intro-container', {
            display: 'block',
            duration: 0,
            delay: 0.3,
            opacity: 1
        })
        .to('.logo', {
            scale: '1, 1',
            y: '0vh',
            x: '0vw',
            duration: 0.2
        })
        .to('.welcome>p:nth-child(1)', {
            opacity: 1,
            duration: 0.2
        }, "<")
        .to('.welcome>p:nth-child(2)', {
            opacity: 1,
            duration: 0.2
        }, "<+=0.1")
        .to('.welcome>p:nth-child(3)', {
            opacity: 1,
            duration: 0.2
        }, "<+=0.15")
        .to('.welcome>p:nth-child(4)', {
            opacity: 1,
            duration: 0.2
        }, "<+=0.2")
        .to('.welcome>p:nth-child(5)', {
            opacity: 1,
            duration: 0.2
        }, "<+=0.25")
        .to('.welcome>p:nth-child(6)', {
            opacity: 1,
            duration: 0.2
        }, "<+=0.3")
        .to('.divider-num', {
            opacity: 1,
            duration: 0.2
        }, "<+=0.4")
        .to('.conversation-item', {
            opacity: 1,
            duration: 0.3
        }, "<+=0.3")
        .to('.arrow', {
            opacity: 1,
            duration: 0.3
        }, "<")
        .to('.toggle-view-button', {
            opacity: 1,
            pointerEvents: 'auto'
        })
        .to('.loading-line', {
            opacity: 0,
            duration: 0
        }, "<")
        .then(() => {
            //textContainer.removeChild(speakerImgContainer)
            //textContainer.removeChild(speakerInfoContainer)
            $(".speaker").remove()
            $(".para").remove()
            $(".tiny").remove()
           return ConversationState = false
        })
        

        logoClickAnimation.play()

    }
}

export {
    words,
    spanIndex,
    transcriptContainer,
    initCon,
    data
}